import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getData, setGetData] = useState([]);
const [loading, setLoading] = useState(false);
const [keyword, setKeyword] = useState("");
const [query, setQuery] = useState("");
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    const response = await API.get(`user-service/privileges/roles?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
    setGetData(response.data.data)
  }
  

  useEffect(() => {
    GetResponseData()
  }, [])

  const [form, setForm] = useState({
    id_role: "",
    code: "",
    username: "",
    password: "",
    email: "",
    level: "",
    unique_id: "",
    ihs_number: "",
    firstname: "",
    lastname: "",
    address: "",
    phone_number: "",
    birth_place: "",
    day_birth: "",
    month_birth: "",
    year_birth: "",
    gender: ""
  });

  const {
    id_role,
    code,
    username,
    password,
    email,
    level,
    unique_id,
    ihs_number,
    firstname,
    lastname,
    address,
    phone_number,
    birth_place,
    day_birth,
    month_birth,
    year_birth,
    gender
  } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      // setLoading(true)
    
      // Insert data for login process
      const response = await API.post("user-service/users/store", {
        id_role: form?.id_role,
        code: form?.code,
        username: form?.username,
        password: form?.password,
        email: form?.email,
        level: form?.level,
        unique_id: form?.unique_id,
        ihs_number: form?.ihs_number,
        firstname: form?.firstname,
        lastname: form?.lastname,
        address: form?.address,
        phone_number: form?.phone_number,
        birth_place: form?.birth_place,
        birth_day: form?.year_birth && form?.month_birth && form?.day_birth?  `${form?.year_birth}-${form?.month_birth}-${form?.day_birth}` : "",
        gender: form?.gender
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
        
       
      }

    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
   
  });

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
       {loading && <LoaderHome />}
       
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah User
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
     
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Pilih Role
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={id_role}  name="id_role" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                {getData.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
            </select>
          </div>
        </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Depan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={firstname} name="firstname" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Belakang
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={lastname} name="lastname" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Username
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={username} name="username" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Code
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={code} name="code" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Email
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='email' value={email} name="email" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Password
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='password' value={password} name="password" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Level
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={level}  name="level" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                <option value="user" style={{ textAlign: "" }}>User</option>                         
                <option value="developer" style={{ textAlign: "" }}>Developer</option>                         
            </select>
          </div>
        </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Jenis Kelamin
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "5px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={gender}  name="gender" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="male" style={{textAlign:""}}>Laki-Laki</option>
              <option value="female" style={{textAlign:""}}>Wanita</option>              
            </select>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Uniq Id
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={unique_id} name="unique_id" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            IHS Number
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='number' value={ihs_number} name="ihs_number" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Tempat Lahir
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={birth_place} name="birth_place" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Tanggal Lahir
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "5px", height: "100%", display: "flex", alignItems: "center" }}>:</p>
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="day_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Day</option>
              <option value="01" style={{textAlign:"center"}}>1</option>
              <option value="02" style={{textAlign:"center"}}>2</option>
              <option value="03" style={{textAlign:"center"}}>3</option>
              <option value="04" style={{textAlign:"center"}}>4</option>
              <option value="05" style={{textAlign:"center"}}>5</option>
              <option value="06" style={{textAlign:"center"}}>6</option>
              <option value="07" style={{textAlign:"center"}}>7</option>
              <option value="08" style={{textAlign:"center"}}>8</option>
              <option value="09" style={{textAlign:"center"}}>9</option>
              <option value="10" style={{textAlign:"center"}}>10</option>
              <option value="11" style={{textAlign:"center"}}>11</option>
              <option value="12" style={{textAlign:"center"}}>12</option>
              <option value="13" style={{textAlign:"center"}}>13</option>
              <option value="14" style={{textAlign:"center"}}>14</option>
              <option value="15" style={{textAlign:"center"}}>15</option>
              <option value="16" style={{textAlign:"center"}}>16</option>
              <option value="17" style={{textAlign:"center"}}>17</option>
              <option value="18" style={{textAlign:"center"}}>18</option>
              <option value="19" style={{textAlign:"center"}}>19</option>
              <option value="20" style={{textAlign:"center"}}>20</option>
              <option value="21" style={{textAlign:"center"}}>21</option>
              <option value="22" style={{textAlign:"center"}}>22</option>
              <option value="23" style={{textAlign:"center"}}>23</option>
              <option value="24" style={{textAlign:"center"}}>24</option>
              <option value="25" style={{textAlign:"center"}}>25</option>
              <option value="26" style={{textAlign:"center"}}>26</option>
              <option value="27" style={{textAlign:"center"}}>27</option>
              <option value="28" style={{textAlign:"center"}}>28</option>
              <option value="29" style={{textAlign:"center"}}>29</option>
              <option value="30" style={{textAlign:"center"}}>30</option>
              <option value="31" style={{textAlign:"center"}}>31</option>    
            </select>
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="month_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Month</option>
              <option value="01" style={{ textAlign:"center"}}>Januari</option>
              <option value="02" style={{textAlign:"center"}}>Februari</option>
              <option value="03" style={{textAlign:"center"}}>Maret</option>
              <option value="04" style={{textAlign:"center"}}>April</option>
              <option value="05" style={{textAlign:"center"}}>Mei</option>
              <option value="06" style={{textAlign:"center"}}>Juni</option>
              <option value="07" style={{textAlign:"center"}}>Juli</option>
              <option value="08" style={{textAlign:"center"}}>Agustus</option>
              <option value="09" style={{textAlign:"center"}}>September</option>
              <option value="10" style={{textAlign:"center"}}>Oktober</option>
              <option value="11" style={{textAlign:"center"}}>November</option>
              <option value="12" style={{textAlign:"center"}}>Desember</option>    
            </select> 
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="year_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
            <option value="" hidden>Year</option>
            <option value="1940">1940</option>
            <option value="1941">1941</option>
            <option value="1942">1942</option>
            <option value="1943">1943</option>
            <option value="1944">1944</option>
            <option value="1945">1945</option>
            <option value="1946">1946</option>
            <option value="1947">1947</option>
            <option value="1948">1948</option>
            <option value="1949">1949</option>
            <option value="1950">1950</option>
            <option value="1951">1951</option>
            <option value="1952">1952</option>
            <option value="1953">1953</option>
            <option value="1954">1954</option>
            <option value="1955">1955</option>
            <option value="1956">1956</option>
            <option value="1957">1957</option>
            <option value="1958">1958</option>
            <option value="1959">1959</option>
            <option value="1960">1960</option>
            <option value="1961">1961</option>
            <option value="1962">1962</option>
            <option value="1963">1963</option>
            <option value="1964">1964</option>
            <option value="1965">1965</option>
            <option value="1966">1966</option>
            <option value="1967">1967</option>
            <option value="1968">1968</option>
            <option value="1969">1969</option>
            <option value="1970">1970</option>
            <option value="1971">1971</option>
            <option value="1972">1972</option>
            <option value="1973">1973</option>
            <option value="1974">1974</option>
            <option value="1975">1975</option>
            <option value="1976">1976</option>
            <option value="1977">1977</option>
            <option value="1978">1978</option>
            <option value="1979">1979</option>
            <option value="1980">1980</option>
            <option value="1981">1981</option>
            <option value="1982">1982</option>
            <option value="1983">1983</option>
            <option value="1984">1984</option>
            <option value="1985">1985</option>
            <option value="1986">1986</option>
            <option value="1987">1987</option>
            <option value="1988">1988</option>
            <option value="1989">1989</option>
            <option value="1990">1990</option>
            <option value="1991">1991</option>
            <option value="1992">1992</option>
            <option value="1993">1993</option>
            <option value="1994">1994</option>
            <option value="1995">1995</option>
            <option value="1996">1996</option>
            <option value="1997">1997</option>
            <option value="1998">1998</option>
            <option value="1999">1999</option>
            <option value="2000">2000</option>
            <option value="2001">2001</option>
            <option value="2002">2002</option>
            <option value="2003">2003</option>
            <option value="2004">2004</option>
            <option value="2005">2005</option>
            <option value="2006">2006</option>
            <option value="2007">2007</option>
            <option value="2008">2008</option>
            <option value="2009">2009</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>         
            </select>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nomor HP
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={phone_number} name="phone_number" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Alamat
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={address} name="address" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>
        
          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  