import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col,Form,Button} from 'reactstrap'
import { API } from "../../config/api";
import {FaPlus, FaUndoAlt, FaCheckCircle, FaMedkit} from 'react-icons/fa'
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import Select from 'react-select';
import LoaderHome from "../Loader/LoaderHome"
import { FormatRupiah } from "@arismun/format-rupiah"
import { useParams } from "react-router-dom";
import ModalAddMedicalRecord from "./ModalMedicalRecord/ModalShowTreatment"
import axios from "axios";

export default function Login() {
  const { id, id_patient_status } = useParams()
  const idParams = { id }
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [limitTreatment, setLimitTreatment] = useState(20000);
  const [ascendingLimit, setAscendingLimit] = useState(0);
  
  const [ascending, setAscending] = useState(1);
  const [search, setSearch] = useState(1);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getDataAppointmentArragmentData, setGetDataAppointmentArragmentData] = useState([]);
  const [getDataAppointmentByIdTreatmentPlan, setGetDataAppointmentByIdTreatmentPlan] = useState([]);
  const [getDataAppointmentByIdTreatmentPlanStatusButton, setGetDataAppointmentByIdTreatmentPlanStatusButton] = useState([]);
  const [getDataAppointmentByIdDoctor, setGetDataAppointmentByIdDoctor] = useState([]);
  const [getDataAppointmentByIdPatient, setGetDataAppointmentByIdPatient] = useState([]);
  const [getDataDetailsApproval, setGetDataDetailsApproval] = useState([]);
  const [getDataTreatmentById, setGetDataTreatmentById] = useState([]);
  const [getDataApprovalById, setGetDataApprovalById] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataTreatmentOneQty, setGetDataTreatmentOneQty] = useState([]);
  const [getDataMedicalRecordPatientForStatusMember, setGetDataMedicalRecordPatientForStatusMember] = useState([]); 
  const [getDataDiagnosaPatient, setGetDataDiagnosaPatient] = useState([]); 
  const [idStoreMedicarRecord, setIdStoreMedicalRecord] = useState([]);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [keyword, setKeyword] = useState("");
  // const [formTreatment, setFormTreatment] = useState({ assistants: [] });
  const [inputList, setInputList] = useState([]);
  // console.log(inputList.length === 0 ? "" : inputList[0].tooth_number. )
  const [dataItem, setGetDataItem] = useState({ id_doctor: "", id_treatment: "", id_room: "", tooth_number: "", qty: "1",choiceGigi: "satu_gigi", treatment_price_min: "", treatment_price_max: "", estimate_price: "", id_description: "", description: "", code_description: "", assistants: ["",""] })
  const [inputListObat, setInputListObat] = useState([{ id_medical_item: "", qty: "", description: "", price: "" }]);
  const initialEstimatePriceTotal = inputList.reduce((total, item) => {
  const estimatePrice = parseFloat(item.estimate_price);
    if (!isNaN(estimatePrice)) {
      return total + estimatePrice;
    }
    return total;
  }, 0); // Initial total is 0

  const [form, setForm] = useState({
    id_appointment: "",
    id_doctor: "",
    id_patient: "",
    name_patient_firstname: "",
    name_patient_lastname: "",
    name_doctor_firstname: "",
    name_doctor_lastname: "",
    anamnesa: "",
    date_plan: "",
    estimate_price_total: initialEstimatePriceTotal, // Inisialisasi dengan nilai 0
    estimate_price_obat: 0,
    time: "",
    choiceGigi: "satu_gigi",
  });

  const [formchoiceGigi, setFormchoiceGigi] = useState({
    choiceGigi: "satu_gigi"
  });

  // modal role add 
  const [idPatientMedicalRecord, setIdModalMedicalRecordPatient] = useState();
  const [idPatientMedicalRecordFirstName, setIdModalMedicalRecordPatientFirstName] = useState();
  const [idPatientMedicalRecordLastName, setIdModalMedicalRecordPatientLastName] = useState();
  const [modalMedicalRecord, setModalMedicalRecord] = useState(false);
  let [cBase64, setBase64] = useState();
  const [getDataFee, setGetDataFee] = useState([]);
  const [getOpenAddObat, setGetOpenAddObat] = useState(false);
  const cleanedArrayInputList = inputList.map((obj) => ({
    ...obj,
    assistants: obj.assistants.filter((assistant) => assistant !== null)
  }));

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const fetchDataRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      const [treatmentOneQty,appointmentByID, dataFee, dataDokter, dataTreatment, dataMedicalRecord ] = await axios.all([
        API.get(`/transaction-service/treatment-one-qtys?page=${page}&limit=${limitTreatment}&ascending=${ascendingLimit}&search=${keyword}`, fetchParams),
        API.get(`/transaction-service/appointments/${id}`, fetchParams),
        API.get(`/transaction-service/fees?page=${page}&limit=${limit}&ascending=${0}`, fetchParams),
        API.get(`/user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`, fetchParams),
        API.get(`/transaction-service/treatments?page=${page}&limit=${limitTreatment}&ascending=${ascendingLimit}&search=${keyword}`, fetchParams),
        API.get(`/emr-service/medical-records?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}&id_patient=${id_patient_status}`, fetchParams),
      ]);

      if (treatmentOneQty.status === 200 &&
        appointmentByID.status === 200 &&
        dataFee.status === 200 &&
        dataDokter.status === 200 &&
        dataTreatment.status === 200 &&
        dataMedicalRecord.status === 200 
        ) {
        setGetDataTreatmentOneQty(treatmentOneQty.data.data)
        setGetDataAppointmentById(appointmentByID.data.data)
        setGetDataAppointmentArragmentData(appointmentByID.data.data.arrangement_data)
        setGetDataAppointmentByIdPatient(appointmentByID.data.data.patient_data)
        setGetDataAppointmentByIdDoctor(appointmentByID.data.data.doctor_data.user_detail)
        setGetDataFee(dataFee.data.data)
        setGetDataDokter(dataDokter.data.data)
        setGetDataTreatment(dataTreatment.data.data)
        setGetDataMedicalRecordPatientForStatusMember(dataMedicalRecord.data.pagination.total)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    fetchDataRef.current();
  }, []);

  const GetResponseDataFee = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/fees?page=${page}&limit=${limit}&ascending=${0}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataFee(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataApproval = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/treatment-plans/${getDataApprovalById}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDetailsApproval(response.data.data.details)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataApprovalByTreatmentPlan = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/treatment-plans/${getDataAppointmentByIdTreatmentPlan}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDetailsApproval(response.data.data.details)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataByIdAppointment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/appointments/${id}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataAppointmentById(response.data.data)
        setGetDataAppointmentArragmentData(response.data.data.arrangement_data)
        setGetDataAppointmentByIdPatient(response.data.data.patient_data)
        setGetDataAppointmentByIdDoctor(response.data.data.doctor_data.user_detail)
        // setGetDataAppointmentByIdTreatmentPlan(response.data.data.treatment_plan_data === null ? "" : response.data.data.treatment_plan_data.id)
        // setGetDataAppointmentByIdTreatmentPlanStatusButton(response.data.data.treatment_plan_data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataByIdAppointmentTreatmentPlan = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/treatment-plans/appointment/${id}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataAppointmentByIdTreatmentPlan(response.data.data.length === 0 || response.data.data.treatment_plan_data === null ? "" : response.data.data.id)
        setGetDataAppointmentByIdTreatmentPlanStatusButton(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      setGetDataAppointmentByIdTreatmentPlan("")
      setGetDataAppointmentByIdTreatmentPlanStatusButton(null)
      // swal({
      //   title: 'Failed',
      //   text: `${error.response.data.message}`,
      //   icon: 'error',
      //   timer: 3000,
      //   buttons: false
      // });
    }
  }

  const GetResponseDataDokter = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`/user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDiagnosaPatient = async () => {
    try {
      setLoading(true)
      // e.preventDefault();
      const response = await API.get(`/user-service/diagnosa-patients?page=${page}&limit=1000&ascending=${ascendingLimit}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDiagnosaPatient(response.data.data)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataTreatmentOneQty = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`/transaction-service/treatment-one-qtys?page=${page}&limit=${limitTreatment}&ascending=${ascendingLimit}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataTreatmentOneQty(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataMedicalRecordPatientForStatusMember = async () => {
    const response = await API.get(`emr-service/medical-records?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}&id_patient=${id_patient_status}`,fetchParams)
    if (response.data.data.length !== 0) {
      setGetDataMedicalRecordPatientForStatusMember(response.data.pagination.total)
    }  
  }

  function calculateTotalPrice() {
    let totalPrice = 0;
    inputList.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });
    return totalPrice;
  }

  const {
    id_appointment,
    id_doctor,
    id_patient,
    anamnesa,
    date_plan,
    estimate_price_total,
    estimate_price_obat,
    time
  } = form;

  // untuk auto merender ketika trigger 
  useEffect(() => {
    GetResponseDataDiagnosaPatient()
    // GetResponseDataTreatmentOneQty()
    // GetResponseDataByIdAppointment()
    // GetResponseDataFee()
    // GetResponseDataDokter()
    GetResponseDataByIdAppointmentTreatmentPlan()
    // GetResponseDataTreatment()
    // GetResponseDataMedicalRecordPatientForStatusMember()
  },[])
  
  useEffect(() => {
    if (getDataApprovalById.length !== 0) {
      GetResponseDataByIdAppointmentTreatmentPlan()
      GetResponseDataApproval()
    }
  }, [getDataApprovalById])
  
  useEffect(() => {
    if (getDataAppointmentByIdTreatmentPlan.length !== 0) {
      GetResponseDataApprovalByTreatmentPlan()
    }
  }, [getDataAppointmentByIdTreatmentPlan])
  
  useEffect(() => {
    setForm({
      ...form,
      id_appointment: `${id}`,
      id_doctor: `${getDataAppointmentById?.id_doctor}`,
      id_patient: `${getDataAppointmentById?.id_patient}`,
      name_patient_firstname: `${getDataAppointmentByIdPatient?.first_name}`,
      name_patient_lastname: `${getDataAppointmentByIdPatient?.last_name}`,
      name_doctor_firstname: `${getDataAppointmentByIdDoctor?.firstname}`,
      name_doctor_lastname: `${getDataAppointmentByIdDoctor?.lastname}`,
      anamnesa: `${getDataAppointmentById?.anamnesa}`,
      date_plan: `${getDataAppointmentById?.date}`,
      time: `${getDataAppointmentById?.time}`,
    })

    setGetDataItem({
      ...dataItem,
      assistants: [`${getDataAppointmentArragmentData?.id_nurse}`,`${getDataAppointmentArragmentData?.id_nurse2}`],
      id_room: `${getDataAppointmentArragmentData?.id_room}`,
      id_doctor: `${getDataAppointmentById?.id_doctor}`,
    })
  }, [getDataAppointmentById, getDataAppointmentByIdDoctor, getDataAppointmentByIdPatient, getDataAppointmentArragmentData])
  
  useEffect(() => {
    if (getDataAppointmentById) {
      // Dapatkan id_doctor dari getDataAppointmentById
      const newIdDoctor = getDataAppointmentById?.id_doctor;

      // Update nilai id_doctor pada inputList
      setInputList((prevInputList) => {
        // Copy inputList yang lama dan ubah id_doctor
        const updatedInputList = prevInputList.map((item) => ({
          ...item,
          id_doctor: newIdDoctor
        }));
        return updatedInputList;
      });
    }
  }, [getDataAppointmentById]);
  
  useEffect(() => {
    if (getDataTreatmentById) {
      const newEstimatePricemin = getDataTreatmentById?.treatment_price_min;
      const newEstimatePricemax = getDataTreatmentById?.treatment_price_max;
  
      setInputList((prevInputList) => {
        const updatedInputList = prevInputList.map((item, index) => ({
          ...item,
          estimate_price: newEstimatePricemin,
          treatment_price_min: newEstimatePricemin,
          treatment_price_max: newEstimatePricemax,
        }));
        return updatedInputList;
      });
    }
  }, [getDataTreatmentById]);

  useEffect(() => {
    setInputList(inputList => {
      return inputList.map(item => ({
        ...item,
        id_room: form.id_room
      }));
    });
  }, [form.id_room]);

  useEffect(() => {
    setInputList(inputList => {
      return inputList.map(item => ({
        ...item,
        assistants: [form?.id_nurse1, form?.id_nurse2]
      }));
    });
  }, [form?.id_nurse1, form?.id_nurse2]);

  useEffect(() => {
    if (dataItem?.id_treatment) {
      const selectedTreatment = getDataTreatment.find((treatment) => treatment.id === dataItem.id_treatment);
      
      if (selectedTreatment) {
        setGetDataItem((prevDataItem) => ({
          ...prevDataItem,
          treatment_price_min: selectedTreatment.treatment_price_min,
          treatment_price_max: selectedTreatment.treatment_price_max,
          estimate_price: selectedTreatment.treatment_price_min,
        }));
      }
    }
  }, [dataItem.id_treatment, getDataTreatment]);

  useEffect(() => {
    if(formchoiceGigi?.choiceGigi === "ra_rb_full") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 ",
      });
      const selectedToothNumbers = [" 18 ", " 17 ", " 16 ", " 15 ", " 14 ", " 13 ", " 12 ", " 11 ", " 21 ", " 22 ", " 23 ", " 24 ", " 25 ", " 26 ", " 27 ", " 28 ", " 55 ", " 54 ", " 53 ", " 52 ", " 51 ", " 61 ", " 62 ", " 63 ", " 64 ", " 65 ", " 85 ", " 84 ", " 83 ", " 82 ", " 81 ", " 71 ", " 72 ", " 73 ", " 74 ", " 75 ", " 48 ", " 47 ", " 46 ", " 45 ", " 44 ", " 43 ", " 42 ", " 41 ", " 31 ", " 32 ", " 33 ", " 34 ", " 35 ", " 36 ", " 37 ", " 38 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "ra_rb_dewasa"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 ",
      });
      const selectedToothNumbers = [" 18 ", " 17 ", " 16 ", " 15 ", " 14 ", " 13 ", " 12 ", " 11 ", " 21 ", " 22 ", " 23 ", " 24 ", " 25 ", " 26 ", " 27 ", " 28 ", " 48 ", " 47 ", " 46 ", " 45 ", " 44 ", " 43 ", " 42 ", " 41 ", " 31 ", " 32 ", " 33 ", " 34 ", " 35 ", " 36 ", " 37 ", " 38 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "ra_rb_anak"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75 ",
      });
      const selectedToothNumbers = [" 55 ", " 54 ", " 53 ", " 52 ", " 51 ", " 61 ", " 62 ", " 63 ", " 64 ", " 65 ", " 85 ", " 84 ", " 83 ", " 82 ", " 81 ", " 71 ", " 72 ", " 73 ", " 74 ", " 75 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
    if(formchoiceGigi?.choiceGigi === "ra_dewasa_anak") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65 ",
      });
      const selectedToothNumbers = [" 18 ", " 17 ", " 16 ", " 15 ", " 14 ", " 13 ", " 12 ", " 11 ", " 21 ", " 22 ", " 23 ", " 24 ", " 25 ", " 26 ", " 27 ", " 28 ", " 55 ", " 54 ", " 53 ", " 52 ", " 51 ", " 61 ", " 62 ", " 63 ", " 64 ", " 65 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "ra_dewasa") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28 ",
      });
      const selectedToothNumbers = [" 18 ", " 17 ", " 16 ", " 15 ", " 14 ", " 13 ", " 12 ", " 11 ", " 21 ", " 22 ", " 23 ", " 24 ", " 25 ", " 26 ", " 27 ", " 28 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "ra_anak") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 55  54  53  52  51  61  62  63  64  65 ",
      });
      const selectedToothNumbers = [" 55 ", " 54 ", " 53 ", " 52 ", " 51 ", " 61 ", " 62 ", " 63 ", " 64 ", " 65 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "rb_dewasa_anak") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 ",
      });
      const selectedToothNumbers = [" 85 ", " 84 ", " 83 ", " 82 ", " 81 ", " 71 ", " 72 ", " 73 ", " 74 ", " 75 ", " 48 ", " 47 ", " 46 ", " 45 ", " 44 ", " 43 ", " 42 ", " 41 ", " 31 ", " 32 ", " 33 ", " 34 ", " 35 ", " 36 ", " 37 ", " 38 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "rb_dewasa") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 ",
      });
      const selectedToothNumbers = [" 48 ", " 47 ", " 46 ", " 45 ", " 44 ", " 43 ", " 42 ", " 41 ", " 31 ", " 32 ", " 33 ", " 34 ", " 35 ", " 36 ", " 37 ", " 38 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "rb_anak") {
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 85  84  83  82  81  71  72  73  74  75 ",
      });
      const selectedToothNumbers = [" 85 ", " 84 ", " 83 ", " 82 ", " 81 ", " 71 ", " 72 ", " 73 ", " 74 ", " 75 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });
    }
    if(formchoiceGigi?.choiceGigi === "regio_1"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 18  17  16  15  14  13  12  11 ",
      });
      const selectedToothNumbers = [" 18 ", " 17 ", " 16 ", " 15 ", " 14 ", " 13 ", " 12 ", " 11 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      }); 
    }
    if(formchoiceGigi?.choiceGigi === "regio_2"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 21  22  23  24  25  26  27  28 ",
      });
      const selectedToothNumbers = [" 21 ", " 22 ", " 23 ", " 24 ", " 25 ", " 26 ", " 27 ", " 28 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
    if(formchoiceGigi?.choiceGigi === "regio_3"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 31  32  33  34  35  36  37  38 ",
      });
      const selectedToothNumbers = [" 31 ", " 32 ", " 33 ", " 34 ", " 35 ", " 36 ", " 37 ", " 38 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
    if(formchoiceGigi?.choiceGigi === "regio_4"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 48  47  46  45  44  43  42  41 ",
      });
      const selectedToothNumbers = [" 48 ", " 47 ", " 46 ", " 45 ", " 44 ", " 43 ", " 42 ", " 41 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      }); 
    }
    if(formchoiceGigi?.choiceGigi === "regio_5"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 55  54  53  52  51 ",
      });
      const selectedToothNumbers = [" 55 ", " 54 ", " 53 ", " 52 ", " 51 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
    if(formchoiceGigi?.choiceGigi === "regio_6"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 61  62  63  64  65 ",
      });
      const selectedToothNumbers = [" 61 ", " 62 ", " 63 ", " 64 ", " 65 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      }); 
    }
    if(formchoiceGigi?.choiceGigi === "regio_7"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 71  72  73  74  75 ",
      });
      const selectedToothNumbers = [" 71 ", " 72 ", " 73 ", " 74 ", " 75 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
    if(formchoiceGigi?.choiceGigi === "regio_8"){
      const polygons = document.querySelectorAll(`polygon`);
      polygons.forEach((polygon) => {
        polygon.style.fill = 'white';        
      });
      setGetDataItem({
        ...dataItem,
        tooth_number: " 85  84  83  82  81 ",
      });
      const selectedToothNumbers = [" 85 ", " 84 ", " 83 ", " 82 ", " 81 "];
      selectedToothNumbers.forEach((toothNumber) => {
        const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${toothNumber}"]`);
        polygons.forEach((polygon) => {
          polygon.style.fill = 'aqua';        
        });
      });  
    }
  }, [formchoiceGigi.choiceGigi]);

  const handleChange = (e) => {
    setFormchoiceGigi({
      ...formchoiceGigi,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      
      // Hitung total harga estimasi
      const totalPrice = calculateTotalPrice();
  
      const updatedForm = {
        ...form,
        estimate_price_total: totalPrice,
      };
  
      const response = await API.post("/emr-service/treatment-plans/store", {
        ...updatedForm,
        treatments: cleanedArrayInputList,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
        setGetDataApprovalById(response.data.id)
        setLoading(false);
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false);
    }
  });
  
  const handleInputChange = (e, index, fieldName) => {
    setGetDataItem({
      ...dataItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange2 = (e) => {
    setGetDataItem({
      ...dataItem,
      id_treatment: e.value,
    });
  };

  const handleInputChange3 = (e) => {
    const selectedDiagnosa = getDataDiagnosaPatient.find(
      (user) => user.id === e.value
    );
  
    setGetDataItem({
      ...dataItem,
      id_description: e.value,
      description: selectedDiagnosa ? selectedDiagnosa.name_str : "", 
      code_description: selectedDiagnosa ? selectedDiagnosa.code_skri : "", 
    });
  };
   
  const [assistantCount, setAssistantCount] = useState(4);

  const handleAssistantChange = (e, index, assistantIndex) => {
    const newList = [...inputList];
    const { name, value } = e.target;
    newList[index].assistants[assistantIndex] = value;
    setInputList(newList);
  };

  const [polygonColors, setPolygonColors] = useState({});

  const initialDataItemObat = {
    id_doctor: "",
    id_treatment: "",
    id_room: dataItem.id_room,
    tooth_number: "",
    qty: "1",
    choiceGigi: "satu_gigi",
    treatment_price_min: "",
    treatment_price_max: "",
    estimate_price: "",
    description: "",
    id_description: "",
    code_description: "",
    assistants: dataItem.assistants,
  };

  const initialDataItemResetObat = {
    id_doctor: "",
    id_treatment: "",
    id_room: dataItem.id_room,
    tooth_number: "",
    qty: "1",
    choiceGigi: "satu_gigi",
    treatment_price_min: "",
    treatment_price_max: "",
    estimate_price: "",
    description: "",
    id_description: "",
    code_description: "",
    assistants: dataItem.assistants,
  };

  const initialDataItem = {
    id_doctor: `${getDataAppointmentById?.id_doctor}`,
    id_treatment: "",
    id_room: dataItem.id_room,
    tooth_number: "",
    qty: "1",
    choiceGigi: "satu_gigi",
    treatment_price_min: "",
    treatment_price_max: "",
    estimate_price: "",
    description: "",
    id_description: "",
    code_description: "",
    assistants: dataItem.assistants,
  };

  const initialDataItemReset = {
    // id_doctor: "",
    id_treatment: "",
    id_room: dataItem.id_room,
    tooth_number: "",
    qty: "1",
    choiceGigi: "satu_gigi",
    treatment_price_min: "",
    treatment_price_max: "",
    estimate_price: "",
    description: "",
    id_description: "",
    code_description: "",
    assistants: dataItem.assistants,
  };

  const initialForm = {
    choiceGigi: "satu_gigi"
  };

  const resetPolygonColors = () => {
    const polygons = document.querySelectorAll('polygon'); // Ambil semua elemen polygon
  
    polygons.forEach((polygon) => {
      if (polygon.style.fill === 'aqua') {
        polygon.style.fill = 'white'; // Ganti warna polygon yang berwarna 'aqua' menjadi 'white'
      }
    });
  };

  const [selectKey, setSelectKey] = useState(0);

  const [selectKeyDiagnosa, setSelectKeyDiagnosa] = useState(0);

  const resetSelect = () => {
    setSelectKey(prevKey => prevKey + 1); 
    setSelectKeyDiagnosa(prevKey => prevKey + 1); 
  };

  const handleAddClick = (e) => {
    // if (dataItem?.id_treatment === "0587ccad-2de6-4366-97a7-56d1eeb31445") {
      
    // }

    // if (dataItem?.id_doctor === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     'Pilih Dokter Terlebih Dahulu',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }

    // if (dataItem?.id_treatment === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     'Pilih Treatment Terlebih Dahulu',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }

    // if (dataItem?.estimate_price === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     'Tentukan Harga Treatment Terlebih Dahulu',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }

    // if (dataItem?.tooth_number === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     'Pilih Nomor Gigi Terlebih Dahulu',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }

    // if (dataItem?.description === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     'Diganosa Kosong',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }

    const matchingTreatment = getDataTreatment.find(item => item.id === dataItem?.id_treatment);
    // if (dataItem?.id_treatment === "closed"){
    if (matchingTreatment && matchingTreatment.name_category === "Perawatan Dasar") {
  } else {
      if (dataItem?.id_doctor === "") {
          setLoading(false);
          return Swal.fire(
              'Pilih Dokter Terlebih Dahulu',
              'Mohon Periksa',
              'warning'
          );
      }
  
      if (dataItem?.id_treatment === "") {
          setLoading(false);
          return Swal.fire(
              'Pilih Treatment Terlebih Dahulu',
              'Mohon Periksa',
              'warning'
          );
      }
  
      if (dataItem?.estimate_price === "") {
          setLoading(false);
          return Swal.fire(
              'Tentukan Harga Treatment Terlebih Dahulu',
              'Mohon Periksa',
              'warning'
          );
      }
  
      if (dataItem?.tooth_number === "") {
          setLoading(false);
          return Swal.fire(
              'Pilih Nomor Gigi Terlebih Dahulu',
              'Mohon Periksa',
              'warning'
          );
      }
  
      if (dataItem?.description === "") {
          setLoading(false);
          return Swal.fire(
              'Diganosa Kosong',
              'Mohon Periksa',
              'warning'
          );
      }
  }

  const toothNumbersArray = dataItem.tooth_number.trim().split(/\s+/);
  const qty = toothNumbersArray.length;

    const newInputItem = {
      id_doctor: dataItem.id_doctor,
      id_treatment: dataItem.id_treatment,
      id_room: dataItem.id_room,
      tooth_number: dataItem.tooth_number,
      qty: qty,
      choiceGigi: dataItem.choiceGigi,
      treatment_price_min: dataItem.treatment_price_min,
      treatment_price_max: dataItem.treatment_price_max,
      estimate_price: dataItem.estimate_price,
      description: dataItem.description,
      id_description: dataItem.id_description,
      code_description: dataItem.code_description,
      assistants: dataItem.assistants,
    };
  
    setInputList((prevInputList) => {
      return [...prevInputList, newInputItem];
    });
    resetSelect()
    setGetDataItem(initialDataItem)
    setFormchoiceGigi(initialForm)
    resetPolygonColors()   
  };

  const handleResetPolygon = (e) => {
    setGetDataItem(initialDataItemReset)
    setFormchoiceGigi(initialForm)
    resetPolygonColors()   
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => { 
    const updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);

    // Hitung ulang total harga estimasi
    let totalPrice = 0;
    updatedInputList.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });

    // Perbarui total harga estimasi dalam form
    setForm({
      ...form,
      estimate_price_total: totalPrice,
    });

    setInputList(updatedInputList);
  };

  const navigateHome = () => {
    navigate('/appointments')
  }

  const navigateAppointment = () => {
    Swal.fire(
      'Selamat Anda Berhasil Melakukan Treatment Pasien',
      'Silahkan Lanjutkan Proses',
      'success'
    )
    navigate('/appointments')
  }

  const RejectTreatment = async (id) => {
    Swal.fire({
      title: `<div>Apakah Kamu Yakin Akan Reject Treatment?</div>`,
      text: ``,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reject'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post(`emr-service/treatment-plan-details/reject/${id}`,
          {
            filename: "signature_approve.png",
            content: cBase64,
          }
          , fetchParams);
        if (response.data.error == false) {
          // GetResponseData()
          swal({
            title: 'Success',
            text: "Anda Telah Berhasil Reject Treatment",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
          GetResponseDataApprovalByTreatmentPlan()

        }
      }
    })
  };

  const approvedItems = getDataDetailsApproval.filter(item => item.patient_approval === 1);

  const ApproveTreatment = async (id, description , id_description, code_description) => {
    Swal.fire({
      title: `<div>Klik Approve Untuk Melanjutkan!</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Approve'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post(`emr-service/treatment-plan-details/approve/${id}`,
          {
            filename: "signature_approve.png",
            content: cBase64,
          }
          , fetchParams);
        if (response.data.error == false) {
          setIdStoreMedicalRecord(id)
          GetResponseDataApprovalByTreatmentPlan()
          handleSubmitObat(id, description, id_description, code_description)
          swal({
            title: 'Success',
            text: "Appointment Anda Telah Berhasil Di proses",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  const totalEstimatedPrice = approvedItems.reduce(
    (accumulator, item) => accumulator + item.estimate_price,
    0
  );
  
  const handleAddClickObat = () => {
    setInputListObat([
      ...inputListObat,
      {
        id_medical_item: "",
        qty: "",
        description: "",
        price: ""
      },
    ]);
  };

  const handleRemoveClickObat = index => {
    const updatedInputListObat = [...inputListObat];
    updatedInputListObat.splice(index, 1);

    // Hitung ulang total harga estimasi obat
    let totalObatPrice = 0;
    updatedInputListObat.forEach((itemObat) => {
      const obatPrice = parseFloat(itemObat.price);
      if (!isNaN(obatPrice)) {
        totalObatPrice += obatPrice;
      }
    });

    // Perbarui total harga estimasi obat dalam form
    setForm({
      ...form,
      estimate_price_obat: totalObatPrice,
    });

    setInputListObat(updatedInputListObat);
  };

  const handleSubmitObat = async (id,description,id_description, code_description) => {
    try {
      const nonEmptyObatItems = inputListObat.filter(item => item.id_medical_item === "");

      // setLoading(true)
      // Insert data for login process
      const response = await API.post("emr-service/medical-records/store", {
        id_patient: `${getDataAppointmentByIdPatient?.id}`,
        diagnose: description,
        id_diagnose: id_description,
        code_diagnose: code_description,
        id_treatment_plan_detail: id,
        id_appointment: idParams?.id,
        medical_prescriptions :  inputListObat.length === 0 || inputListObat[0].id_medical_item === "" ? [] : inputListObat
      }, fetchParams);

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })

        setInputListObat([])
        // window.location.reload()
        // setLoading(false
      }

    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  
  };

  let totalDataFeePrice = 0;

  getDataFee.forEach((user) => {
    totalDataFeePrice += user?.price || 0; 
  });
  let totalDataTreatmentPrice = 0;
  getDataDetailsApproval.forEach((user) => {
    totalDataFeePrice += user?.estimate_price*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty) || 0; 
  });

  let totalDataTreatmentPriceList = 0;

  inputList.forEach((user) => {
    // Convert the estimate_price to a number using parseInt
    const estimatePrice = parseInt(user?.estimate_price || 0, 10);
    totalDataTreatmentPriceList += estimatePrice*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty);
  });
  
  let toltalDataTreatmentPriceOn = totalDataFeePrice + totalDataTreatmentPriceList;

  const handleSubmitMedicalInvoice = async () => {
    Swal.fire({
      title: `<div>Apakah Anda Telah Selesai Menyelesaikan Treatment?</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Selesai'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post("emr-service/medical-invoices/store", {
          id_appointment: `${id}`
        }, fetchParams);

        if (response.data.error === false) {
        Swal.fire(
            'Selamat Anda Berhasil Melakukan Treatment Pasien',
            'Silahkan Lanjutkan Proses',
            'success'
          )
          navigate('/appointments')
        }
      }
    })
  };

  const viewModalMedicalRecord = () => {
    setIdModalMedicalRecordPatient(getDataAppointmentById?.id_patient)
    setIdModalMedicalRecordPatientFirstName(getDataAppointmentByIdPatient?.first_name)
    setIdModalMedicalRecordPatientLastName(getDataAppointmentByIdPatient?.last_name)
    setModalMedicalRecord(true)
  }

 function formatToRupiah(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    // Format angka ke format mata uang Rupiah
    const formattedString = formatter.format(angka);
  
    // Menghilangkan simbol "RP" (simbol mata uang) dari hasil format
    const rupiahWithoutSymbol = formattedString.replace('Rp', '');
  
    return rupiahWithoutSymbol.trim(); // Menghilangkan spasi yang mungkin ada
  }

  const handlePolygonClick = (e, index) => {
    const polygon = e.target;
    const newFillColor = polygon.style.fill === 'aqua' ? 'white' : 'aqua';
    polygon.style.fill = newFillColor;
    const nomorGigi = polygon.getAttribute('data-nomor-gigi');
    setGetDataItem((prevDataItem) => {
      // Salin dataItem sebelumnya
      const updatedDataItem = { ...prevDataItem };

      // Modifikasi tooth_number berdasarkan nomor gigi yang diklik
      const updatedToothNumber = updatedDataItem.tooth_number.includes(nomorGigi)
        ? updatedDataItem.tooth_number.replace(nomorGigi, '')
        : updatedDataItem.tooth_number + '' + nomorGigi;
  
      // Setel tooth_number yang diperbarui ke dalam dataItem
      updatedDataItem.tooth_number = updatedToothNumber;
  
      return updatedDataItem;
    });
  }; 
    
  const handlePolygonClickSatuBagian = (e) => {
    const nomorGigi = e.target.getAttribute('data-nomor-gigi');

    // Mendapatkan semua polygon dengan nomor gigi yang sama
    const polygons = document.querySelectorAll(`polygon[data-nomor-gigi="${nomorGigi}"]`);

    // Mengubah warna semua polygon dengan nomor gigi yang sama
    polygons.forEach((polygon) => {
      const newFillColor = polygon.style.fill === 'aqua' ? 'white' : 'aqua';
      polygon.style.fill = newFillColor;
    });

    setGetDataItem((prevDataItem) => {
      // Salin dataItem sebelumnya
      const updatedDataItem = { ...prevDataItem };

      // Modifikasi tooth_number berdasarkan nomor gigi yang diklik
      const updatedToothNumber = updatedDataItem.tooth_number.includes(nomorGigi)
        ? updatedDataItem.tooth_number.replace(nomorGigi, '')
        : updatedDataItem.tooth_number + '' + nomorGigi;

      // Setel tooth_number yang diperbarui ke dalam dataItem
      updatedDataItem.tooth_number = updatedToothNumber;

      return updatedDataItem;
    });
  };

  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
  <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
    {modalMedicalRecord  && <ModalAddMedicalRecord  idPatientMedicalRecord={idPatientMedicalRecord} idPatientMedicalRecordFirstName={idPatientMedicalRecordFirstName} idPatientMedicalRecordLastName={idPatientMedicalRecordLastName} show={modalMedicalRecord} onHide={() => setModalMedicalRecord(false)} />}
    {loading && <LoaderHome />}

    <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px", fontFamily:"sans-serif" }}>
      <div style={{fontSize:"16px",display:"flex", aunlocklignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
         <div onClick={viewModalMedicalRecord} style={{display:"flex" , marginRight:"10px",fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius:"5px", borderBottomLeftRadius:"5px"}}>
          <div style={{display:"flex",alignItems:"center", height:"100%", marginRight:"3px"}}>
            <FaMedkit style={{display:"flex",alignItems:"center", fontSize:"15px"}}/>
          </div>
          <div style={{display:"flex", alignItems:"center", height:"100%", fontSize:"12px"}}>
            Data Medis
          </div>
        </div>
        {/* <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
          <FaAddressBook style={{marginRight:"5px"}}/>Treatment Plan
        </div> */}
        <div>
          Treatment Plan 
        </div>
      </div>
    </div>
 
    {getDataAppointmentByIdTreatmentPlanStatusButton === null ? 
    <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{padding:"0px 0px 30px 0px", fontFamily: "sans-serif" }} >
      <div style={{padding:"10px 20px 20px 20px", backgroundColor:"#50D1FF", borderBottom: "5px solid #EEEEEE"}}>
        <div className="" style={{display:"flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{width: "100%"}}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Dokter</div>
                <input disabled type="text"  value={form?.name_doctor_firstname + ` ` + form?.name_doctor_lastname} name="id_doctor" onChange={handleChange}  style={{backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>
          </div>
          <div style={{flex:"50%", display:"flex", alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pasien</div>
                  <input disabled type="text" value={form?.name_patient_firstname + ` ` + form?.name_patient_lastname + "  " + (getDataMedicalRecordPatientForStatusMember === 0 || getDataMedicalRecordPatientForStatusMember === "0"? "(pasien baru)" : "")} name="id_patient" onChange={handleChange}  
                  style={{color: getDataMedicalRecordPatientForStatusMember === 0 || getDataMedicalRecordPatientForStatusMember === "0"? "red" :"", backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
        </div>

      <div className="mt-2" style={{ display: "flex"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
          <div style={{width: "100%"}}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Dan Waktu Appointment</div>
            <input autoFofcus type='text' disabled
              value={
              `${form?.date_plan}`.split('-')[2] + ` ` +
              (`${form?.date_plan}`.split('-')[1] === "01" ? "Januari" : "" ||
              `${form?.date_plan}`.split('-')[1] === "02" ? "Februari" : "" ||
              `${form?.date_plan}`.split('-')[1] === "03" ? "Maret" : "" ||
              `${form?.date_plan}`.split('-')[1] === "04" ? "April" : "" ||  
              `${form?.date_plan}`.split('-')[1] === "05" ? "Mei" : "" ||
              `${form?.date_plan}`.split('-')[1] === "06" ? "Juni" : "" ||
              `${form?.date_plan}`.split('-')[1] === "07" ? "Juli" : "" ||
              `${form?.date_plan}`.split('-')[1] === "08" ? "Agustus" : "" ||
              `${form?.date_plan}`.split('-')[1] === "09" ? "September" : "" ||
              `${form?.date_plan}`.split('-')[1] === "10" ? "Oktober" : "" ||
              `${form?.date_plan}`.split('-')[1] === "11" ? "November" : "" ||
              `${form?.date_plan}`.split('-')[1] === "12" ? "Desember" : "" ) + ` ` +
              `${form?.date_plan}`.split('-')[0] + "   |   " +  `${form?.time}`.split(':')[0] +` : `+ `${form?.time}`.split(':')[1]
              }
              name="date_plan" style={{ backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>             
            </div>
            </div>
            <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Anamnesa</div>
                <input  className="focused" type="text" value={form?.anamnesa} name="anamnesa" onChange={handleChangeForm}  style={{ backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Keluhan Pasien</div> */}
            </div>
          </div>
        </div>
      </div>
          
    <div className="mt-2" style={{display:"flex", fontWeight:"bold", padding:"0px 10px"}}>Treatment :</div>          
      <div className="App">     
        <div className="mt-2" style={{padding:"0px 10px"}}>  
            <>
            <div className="" style={{ display: "flex" }}>
              <div style={{ flex: "35%", display: "flex", alignItems: "", marginRight: "10px" }}>
                <div style={{ width: "100%" }}>
                  <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Dokter</div>
                  <select className="form-select" aria-label="Default select example" value={dataItem?.id_doctor} onChange={handleInputChange} name="id_doctor" style={{textAlign:"", cursor:"pointer"}}>
                    <option value="">Select Dokter</option>
                    {getDataDokter.map((user, index) => (
                      <option value={user?.id} style={{textAlign: ""}}>{user?.firstname} {user?.lastname}</option>
                    ))}
                  </select>
                </div>
              </div>
                  
            {/* <div style={{flex:"35%", display:"flex",alignItems:"center", marginRight:"10px"}}>
                <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Treatment</div>
                  <select  className="form-select" aria-label="Default select example" value={dataItem?.id_treatment}  onChange={handleInputChange}  name="id_treatment" style={{ textAlign:"", cursor:"pointer"}}>
                  <option value="">Pilih Treatment</option>
                    {getDataTreatment.map((user, index) => (
                      <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>
                    ))}         
                </select>
                </div>
              </div> */}

            <div style={{ flex: "35%", display: "flex", alignItems: "center", marginRight: "10px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ fontSize: "12px", color: "", fontStyle: "", fontWeight: "bold" }}>Treatment</div>
                <Select
                  key={selectKey}
                  name="id_treatment"
                  onChange={handleInputChange2}
                  options={getDataTreatment.map(user => ({
                    value: user.id,
                    label: `${user.code_icd9 ? user.code_icd9 : 'SDC '} | ${user.name}  ${user.code_icd9 ? " | Satu Sehat" : ""}`,
                    color: user.code_icd9 === null ? 'black' : 'blue'  // Add a color property to each option
                  }))}
                  placeholder="Pilih Treatment"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "1px solid #ced4da",
                      boxShadow: "none",
                      "&:hover": { borderColor: "#ced4da" },
                    }),
                    menu: (base) => ({
                      ...base,
                      marginTop: 0,
                    }),
                    singleValue: (base, state) => ({
                      ...base,
                      color: state.data.color,  // Use the color defined in the options
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "black",  // Keep the placeholder color black
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.data.color,  // Use the color defined in the options
                      backgroundColor: state.isSelected ? (state.data.color === 'black' ? 'black' : 'blue') : 'white',
                    }),
                  }}
                />
              </div>
            </div>

            <div style={{flex:"30%", display:"flex",alignItems:"center"}}>
              <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold", display:"flex", justifyContent:""}}>Harga : {formatToRupiah(dataItem.treatment_price_min)} - {formatToRupiah(dataItem.treatment_price_max)}</div>
                <input  className="focused"  step={50000}  type='number' placeholder="Masukan Harga"  name="estimate_price" min={dataItem.treatment_price_min} max={dataItem.treatment_price_max}  value={dataItem?.estimate_price}  onChange={handleInputChange}
                  style={{ width: "100%",height:"37.5px", textAlign: "center",border:"1px solid #C9C9C9", borderRadius:"5px" }}/>
              </div>
            </div>

          </div>

          <div className="mt-4 mb-3" style={{ display: "flex"}}>
            <div style={{flex:"10%", display:"flex",alignItems:" center",fontSize:"15px", color:"", fontStyle:"", fontWeight:"bold"}}>
              Nomor Gigi :
            </div>
              
            <div className="mr-2" style={{ flex: "30%", display: "flex", alignItems: "center" }}>
              <div style={{ width: "100%" }}>
                <select className="form-select" aria-label="Default select example" value={formchoiceGigi?.choiceGigi}  onChange={handleChange}  name="choiceGigi" style={{ textAlign:"", cursor:"pointer"}}>
                  <option value="satu_gigi">Satu Gigi</option>
                  <option value="per_gigi">Bagian Gigi</option> 
                  <option value="ra_rb_full">RA + RB ( Dewasa+Anak )</option>
                  <option value="ra_rb_dewasa">RA + RB Dewasa</option>
                  <option value="ra_rb_anak">RA + RB Anak</option>
                  <option value="ra_dewasa_anak">RA ( Dewasa+Anak )</option>
                  <option value="ra_dewasa">RA ( Dewasa )</option>
                  <option value="ra_anak">RA ( Anak )</option>
                  <option value="rb_dewasa_anak">RB ( Dewasa+Anak )</option>
                  <option value="rb_dewasa">RB ( Dewasa )</option>
                  <option value="rb_anak">RB ( Anak )</option>
                  <option value="regio_1">Regio 1</option>
                  <option value="regio_2">Regio 2</option>
                  <option value="regio_3">Regio 3</option>
                  <option value="regio_4">Regio 4</option>
                  <option value="regio_5">Regio 5</option>
                  <option value="regio_6">Regio 6</option>
                  <option value="regio_7">Regio 7</option>
                  <option value="regio_8">Regio 8</option>
                  {/* <option value="semua_rahang_atas">Semua Rahang Atas</option> */}
                </select>
              </div>
            </div>

             <div style={{ flex: "35%", display: "flex", alignItems: "center", marginRight: "10px" }}>
              <div style={{ width: "100%", cursor:"pointer" }}>
                <Select
                  key={selectKeyDiagnosa}
                  name="description"
                  // className="form-select"
                  // aria-label="Default select example"
                  onChange={handleInputChange3}
                  options={getDataDiagnosaPatient.map(user => ({ value: user.id, label: user.code_skri + " | " + user.name_str }))}
                  // value={dataItem?.id_treatment}
                  placeholder="Pilih Diagnosa"
                />
              </div>
            </div>

            {/* <div style={{ flex: "30%", display: "flex", alignItems: "" }}>
              <div style={{ width: "100%" }}>
                <input  className="focused" type='text' placeholder="Masukan diagnosa"  name="description" value={dataItem?.description} onChange={handleInputChange} style={{backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", height:"37.5px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
              </div>
            </div> */}

            <div style={{flex:"30%", display:"flex",alignItems:"start", marginLeft:"10px"}}>
                
            </div>
          </div>
              
          <div style={{ display:"flex",alignItems:" center",fontSize:"15px", color:"", fontStyle:"", fontWeight:"bold"}}>
            Keterangan Gigi : {infoGigi}
          </div>
                
          {formchoiceGigi?.choiceGigi === "satu_gigi" ||  formchoiceGigi?.choiceGigi === "ra_rb_full" || formchoiceGigi?.choiceGigi === "ra_rb_dewasa" ||
            formchoiceGigi?.choiceGigi === "ra_dewasa_anak" ||  formchoiceGigi?.choiceGigi === "ra_dewasa" ||  formchoiceGigi?.choiceGigi === "ra_anak" ||  
            formchoiceGigi?.choiceGigi === "rb_dewasa_anak" ||  formchoiceGigi?.choiceGigi === "rb_dewasa" ||  formchoiceGigi?.choiceGigi === "rb_anak" ||
            formchoiceGigi?.choiceGigi === "ra_rb_anak" ||  formchoiceGigi?.choiceGigi === "regio_1" || formchoiceGigi?.choiceGigi === "regio_2" ||
            formchoiceGigi?.choiceGigi === "regio_3" ||  formchoiceGigi?.choiceGigi === "regio_4" || formchoiceGigi?.choiceGigi === "regio_5" ||
            formchoiceGigi?.choiceGigi === "regio_6" ||  formchoiceGigi?.choiceGigi === "regio_7" || formchoiceGigi?.choiceGigi === "regio_8"?
            <Col className="mt-2" xl='12' sm='12' style={{ padding: "0px 0px", fontSize: "12px" }} >
            {/* Gigi Atas Dewasa */}
            <div style={{ display: "flex" }}>
              <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>18</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Atas */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 18 "
                        onMouseEnter={() => setInfoGigi("18")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Bawah */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 18 "
                        onMouseEnter={() => setInfoGigi("18")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kanan */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 18 "
                        onMouseEnter={() => setInfoGigi("18")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kiri */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 18 "
                        onMouseEnter={() => setInfoGigi("18")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Center */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 18 "
                        onMouseEnter={() => setInfoGigi("18")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>17</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Atas */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 17 "
                        onMouseEnter={() => setInfoGigi("17")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Bawah */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 17 "
                        onMouseEnter={() => setInfoGigi("17")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kanan */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 17 "
                        onMouseEnter={() => setInfoGigi("17")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kiri */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 17 "
                        onMouseEnter={() => setInfoGigi("17")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Center */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 17 "
                        onMouseEnter={() => setInfoGigi("17")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>16</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Atas */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 16 "
                        onMouseEnter={() => setInfoGigi("16")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Bawah */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 16 "
                        onMouseEnter={() => setInfoGigi("16")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kanan */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 16 "
                        onMouseEnter={() => setInfoGigi("16")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kiri */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 16 "
                        onMouseEnter={() => setInfoGigi("16")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Center */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 16 "
                        onMouseEnter={() => setInfoGigi("16")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>15</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Atas */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 15 "
                        onMouseEnter={() => setInfoGigi("15")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Bawah */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 15 "
                        onMouseEnter={() => setInfoGigi("15")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kanan */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 15 "
                        onMouseEnter={() => setInfoGigi("15")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kiri */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 15 "
                        onMouseEnter={() => setInfoGigi("15")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Center */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 15 "
                        onMouseEnter={() => setInfoGigi("15")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>14</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Atas */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 14 "
                        onMouseEnter={() => setInfoGigi("14")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Bawah */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 14 "
                        onMouseEnter={() => setInfoGigi("14")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kanan */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 14 "
                        onMouseEnter={() => setInfoGigi("14")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                
                      {/* Kiri */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 14 "
                        onMouseEnter={() => setInfoGigi("14")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Center */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 14 "
                        onMouseEnter={() => setInfoGigi("14")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>13</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 13 "
                        onMouseEnter={() => setInfoGigi("13")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 13 "
                        onMouseEnter={() => setInfoGigi("13")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                  
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 13 "
                        onMouseEnter={() => setInfoGigi("13")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
  
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 13 "
                        onMouseEnter={() => setInfoGigi("13")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>12</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 12 "
                        onMouseEnter={() => setInfoGigi("12")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 12 "
                        onMouseEnter={() => setInfoGigi("12")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 12 "
                        onMouseEnter={() => setInfoGigi("12")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 12 "
                        onMouseEnter={() => setInfoGigi("12")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>11</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 11 "
                        onMouseEnter={() => setInfoGigi("11")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 11 "
                        onMouseEnter={() => setInfoGigi("11")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 11 "
                        onMouseEnter={() => setInfoGigi("11")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 11 "
                        onMouseEnter={() => setInfoGigi("11")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
              </div>
  
              <div style={{ flex: "50%", display: "flex", justifyContent: "start", paddingLeft: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>21</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 21 "
                        onMouseEnter={() => setInfoGigi("21")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 21 "
                        onMouseEnter={() => setInfoGigi("21")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 21 "
                        onMouseEnter={() => setInfoGigi("21")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 21 "
                        onMouseEnter={() => setInfoGigi("21")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>22</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 22 "
                        onMouseEnter={() => setInfoGigi("22")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 22 "
                        onMouseEnter={() => setInfoGigi("22")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 22 "
                        onMouseEnter={() => setInfoGigi("22")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 22 "
                        onMouseEnter={() => setInfoGigi("22")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>23</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 23 "
                        onMouseEnter={() => setInfoGigi("23")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 23 "
                        onMouseEnter={() => setInfoGigi("23")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 23 "
                        onMouseEnter={() => setInfoGigi("23")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 23 "
                        onMouseEnter={() => setInfoGigi("23")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>24</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 24 "
                        onMouseEnter={() => setInfoGigi("24")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 24 "
                        onMouseEnter={() => setInfoGigi("24")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 24 "
                        onMouseEnter={() => setInfoGigi("24")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 24 "
                        onMouseEnter={() => setInfoGigi("24")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 24 "
                        onMouseEnter={() => setInfoGigi("24")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>25</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 25 "
                        onMouseEnter={() => setInfoGigi("25")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 25 "
                        onMouseEnter={() => setInfoGigi("25")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 25 "
                        onMouseEnter={() => setInfoGigi("25")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 25 "
                        onMouseEnter={() => setInfoGigi("25")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 25 "
                        onMouseEnter={() => setInfoGigi("25")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>26</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 26 "
                        onMouseEnter={() => setInfoGigi("26")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 26 "
                        onMouseEnter={() => setInfoGigi("26")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 26 "
                        onMouseEnter={() => setInfoGigi("26")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 26 "
                        onMouseEnter={() => setInfoGigi("26")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 26 "
                        onMouseEnter={() => setInfoGigi("26")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>27</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 27 "
                        onMouseEnter={() => setInfoGigi("27")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 27 "
                        onMouseEnter={() => setInfoGigi("27")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 27 "
                        onMouseEnter={() => setInfoGigi("27")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 27 "
                        onMouseEnter={() => setInfoGigi("27")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 27 "
                        onMouseEnter={() => setInfoGigi("27")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>28</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 28 "
                        onMouseEnter={() => setInfoGigi("28")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 28 "
                        onMouseEnter={() => setInfoGigi("28")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 28 "
                        onMouseEnter={() => setInfoGigi("28")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 28 "
                        onMouseEnter={() => setInfoGigi("28")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 28 "
                        onMouseEnter={() => setInfoGigi("28")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
                
              </div>
      
            </div>
        
            {/* Gigi Atas Anak */}
            <div style={{ display: "flex" }}>
              <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "30px" }}>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>55</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 55 "
                        onMouseEnter={() => setInfoGigi("55")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 55 "
                        onMouseEnter={() => setInfoGigi("55")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 55 "
                        onMouseEnter={() => setInfoGigi("55")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 55 "
                        onMouseEnter={() => setInfoGigi("55")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 55 "
                        onMouseEnter={() => setInfoGigi("55")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>54</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 54 "
                        onMouseEnter={() => setInfoGigi("54")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 54 "
                        onMouseEnter={() => setInfoGigi("54")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 54 "
                        onMouseEnter={() => setInfoGigi("54")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 54 "
                        onMouseEnter={() => setInfoGigi("54")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 54 "
                        onMouseEnter={() => setInfoGigi("54")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>53</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 53 "
                        onMouseEnter={() => setInfoGigi("53")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 53 "
                        onMouseEnter={() => setInfoGigi("53")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 53 "
                        onMouseEnter={() => setInfoGigi("53")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 53 "
                        onMouseEnter={() => setInfoGigi("53")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>52</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 52 "
                        onMouseEnter={() => setInfoGigi("52")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 52 "
                        onMouseEnter={() => setInfoGigi("52")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 52 "
                        onMouseEnter={() => setInfoGigi("52")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 52 "
                        onMouseEnter={() => setInfoGigi("52")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>51</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 51 "
                        onMouseEnter={() => setInfoGigi("51")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 51 "
                        onMouseEnter={() => setInfoGigi("51")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 51 "
                        onMouseEnter={() => setInfoGigi("51")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 51 "
                        onMouseEnter={() => setInfoGigi("51")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
              </div>
  
              <div style={{ flex: "50%", display: "flex", justifyContent: "start", paddingLeft: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>61</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 61 "
                        onMouseEnter={() => setInfoGigi("61")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 61 "
                        onMouseEnter={() => setInfoGigi("61")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 61 "
                        onMouseEnter={() => setInfoGigi("61")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 61 "
                        onMouseEnter={() => setInfoGigi("61")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>62</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 62 "
                        onMouseEnter={() => setInfoGigi("62")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 62 "
                        onMouseEnter={() => setInfoGigi("62")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 62 "
                        onMouseEnter={() => setInfoGigi("62")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 62 "
                        onMouseEnter={() => setInfoGigi("62")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>63</div>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 63 "
                        onMouseEnter={() => setInfoGigi("63")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 63 "
                        onMouseEnter={() => setInfoGigi("63")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 63 "
                        onMouseEnter={() => setInfoGigi("63")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 63 "
                        onMouseEnter={() => setInfoGigi("63")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>64</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 64 "
                        onMouseEnter={() => setInfoGigi("64")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 64 "
                        onMouseEnter={() => setInfoGigi("64")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 64 "
                        onMouseEnter={() => setInfoGigi("64")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 64 "
                        onMouseEnter={() => setInfoGigi("64")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 64 "
                        onMouseEnter={() => setInfoGigi("64")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>65</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 65 "
                        onMouseEnter={() => setInfoGigi("65")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 65 "
                        onMouseEnter={() => setInfoGigi("65")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 65 "
                        onMouseEnter={() => setInfoGigi("65")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 65 "
                        onMouseEnter={() => setInfoGigi("65")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 65 "
                        onMouseEnter={() => setInfoGigi("65")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
  
                </div>
  
    
      
    
              </div>
            </div>
        
            {/* Gigi Bawah Anak */}
            <div className="mt-2" style={{ display: "flex" }}>
              <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "30px" }}>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 85 "
                        onMouseEnter={() => setInfoGigi("85")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 85 "
                        onMouseEnter={() => setInfoGigi("85")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 85 "
                        onMouseEnter={() => setInfoGigi("85")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 85 "
                        onMouseEnter={() => setInfoGigi("85")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 85 "
                        onMouseEnter={() => setInfoGigi("85")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>85</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 84 "
                        onMouseEnter={() => setInfoGigi("84")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 84 "
                        onMouseEnter={() => setInfoGigi("84")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 84 "
                        onMouseEnter={() => setInfoGigi("84")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 84 "
                        onMouseEnter={() => setInfoGigi("84")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 84 "
                        onMouseEnter={() => setInfoGigi("84")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>84</div>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 83 "
                        onMouseEnter={() => setInfoGigi("83")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 83 "
                        onMouseEnter={() => setInfoGigi("83")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 83 "
                        onMouseEnter={() => setInfoGigi("83")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 83 "
                        onMouseEnter={() => setInfoGigi("83")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                  
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>83</div>
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 82 "
                        onMouseEnter={() => setInfoGigi("82")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 82 "
                        onMouseEnter={() => setInfoGigi("82")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 82 "
                        onMouseEnter={() => setInfoGigi("82")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 82 "
                        onMouseEnter={() => setInfoGigi("82")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>82</div>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 81 "
                        onMouseEnter={() => setInfoGigi("81")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 81 "
                        onMouseEnter={() => setInfoGigi("81")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 81 "
                        onMouseEnter={() => setInfoGigi("81")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 81 "
                        onMouseEnter={() => setInfoGigi("81")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>81</div>
                </div>
              </div>
  
              <div style={{ flex: "50%", display: "flex", justifyContent: "start", paddingLeft: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 71 "
                        onMouseEnter={() => setInfoGigi("71")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 71 "
                        onMouseEnter={() => setInfoGigi("71")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 71 "
                        onMouseEnter={() => setInfoGigi("71")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 71 "
                        onMouseEnter={() => setInfoGigi("71")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>71</div>
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 72 "
                        onMouseEnter={() => setInfoGigi("72")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 72 "
                        onMouseEnter={() => setInfoGigi("72")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 72 "
                        onMouseEnter={() => setInfoGigi("72")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 72 "
                        onMouseEnter={() => setInfoGigi("72")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>72</div>
  
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 73 "
                        onMouseEnter={() => setInfoGigi("73")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 73 "
                        onMouseEnter={() => setInfoGigi("73")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 73 "
                        onMouseEnter={() => setInfoGigi("73")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 73 "
                        onMouseEnter={() => setInfoGigi("73")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>73</div>
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 74 "
                        onMouseEnter={() => setInfoGigi("74")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 74 "
                        onMouseEnter={() => setInfoGigi("74")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 74 "
                        onMouseEnter={() => setInfoGigi("74")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 74 "
                        onMouseEnter={() => setInfoGigi("74")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 74 "
                        onMouseEnter={() => setInfoGigi("74")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>74</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 75 "
                        onMouseEnter={() => setInfoGigi("75")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 75 "
                        onMouseEnter={() => setInfoGigi("75")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 75 "
                        onMouseEnter={() => setInfoGigi("75")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 75 "
                        onMouseEnter={() => setInfoGigi("75")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 75 "
                        onMouseEnter={() => setInfoGigi("75")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>75</div>
  
                </div>
    
              </div>
            </div>
        
            {/* Gigi Bawah Dewasa */}
            <div style={{ display: "flex" }}>
              <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 48 "
                        onMouseEnter={() => setInfoGigi("48")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 48 "
                        onMouseEnter={() => setInfoGigi("48")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 48 "
                        onMouseEnter={() => setInfoGigi("48")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 48 "
                        onMouseEnter={() => setInfoGigi("48")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 48 "
                        onMouseEnter={() => setInfoGigi("48")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>48</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 47 "
                        onMouseEnter={() => setInfoGigi("47")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 47 "
                        onMouseEnter={() => setInfoGigi("47")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 47 "
                        onMouseEnter={() => setInfoGigi("47")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 47 "
                        onMouseEnter={() => setInfoGigi("47")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 47 "
                        onMouseEnter={() => setInfoGigi("47")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>47</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 46 "
                        onMouseEnter={() => setInfoGigi("46")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 46 "
                        onMouseEnter={() => setInfoGigi("46")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 46 "
                        onMouseEnter={() => setInfoGigi("46")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 46 "
                        onMouseEnter={() => setInfoGigi("46")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 46 "
                        onMouseEnter={() => setInfoGigi("46")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>46</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 45 "
                        onMouseEnter={() => setInfoGigi("45")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 45 "
                        onMouseEnter={() => setInfoGigi("45")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 45 "
                        onMouseEnter={() => setInfoGigi("45")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 45 "
                        onMouseEnter={() => setInfoGigi("45")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 45 "
                        onMouseEnter={() => setInfoGigi("45")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>45</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 44 "
                        onMouseEnter={() => setInfoGigi("44")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 44 "
                        onMouseEnter={() => setInfoGigi("44")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 44 "
                        onMouseEnter={() => setInfoGigi("44")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 44 "
                        onMouseEnter={() => setInfoGigi("44")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 44 "
                        onMouseEnter={() => setInfoGigi("44")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>44</div>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 43 "
                        onMouseEnter={() => setInfoGigi("43")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 43 "
                        onMouseEnter={() => setInfoGigi("43")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 43 "
                        onMouseEnter={() => setInfoGigi("43")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 43 "
                        onMouseEnter={() => setInfoGigi("43")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>43</div>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 42 "
                        onMouseEnter={() => setInfoGigi("42")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 42 "
                        onMouseEnter={() => setInfoGigi("42")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 42 "
                        onMouseEnter={() => setInfoGigi("42")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 42 "
                        onMouseEnter={() => setInfoGigi("42")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>42</div>
  
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 41 "
                        onMouseEnter={() => setInfoGigi("41")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 41 "
                        onMouseEnter={() => setInfoGigi("41")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 41 "
                        onMouseEnter={() => setInfoGigi("41")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 41 "
                        onMouseEnter={() => setInfoGigi("41")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>41</div>
  
                </div>
              </div>
  
              <div style={{ flex: "50%", display: "flex", justifyContent: "start", paddingLeft: "30px" }}>
      
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 31 "
                        onMouseEnter={() => setInfoGigi("31")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 31 "
                        onMouseEnter={() => setInfoGigi("31")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 31 "
                        onMouseEnter={() => setInfoGigi("31")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 31 "
                        onMouseEnter={() => setInfoGigi("31")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>31</div>
  
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 32 "
                        onMouseEnter={() => setInfoGigi("32")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 32 "
                        onMouseEnter={() => setInfoGigi("32")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 32 "
                        onMouseEnter={() => setInfoGigi("32")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 32 "
                        onMouseEnter={() => setInfoGigi("32")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>32</div>
  
                </div>
      
                <div style={{ width: "42px", margin: "5px" }}>
  
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 33 "
                        onMouseEnter={() => setInfoGigi("33")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 33 "
                        onMouseEnter={() => setInfoGigi("33")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 33 "
                        onMouseEnter={() => setInfoGigi("33")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 33 "
                        onMouseEnter={() => setInfoGigi("33")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>33</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 34 "
                        onMouseEnter={() => setInfoGigi("34")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 34 "
                        onMouseEnter={() => setInfoGigi("34")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 34 "
                        onMouseEnter={() => setInfoGigi("34")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 34 "
                        onMouseEnter={() => setInfoGigi("34")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 34 "
                        onMouseEnter={() => setInfoGigi("34")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>34</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 35 "
                        onMouseEnter={() => setInfoGigi("35")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 35 "
                        onMouseEnter={() => setInfoGigi("35")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 35 "
                        onMouseEnter={() => setInfoGigi("35")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 35 "
                        onMouseEnter={() => setInfoGigi("35")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 35 "
                        onMouseEnter={() => setInfoGigi("35")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>35</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 36 "
                        onMouseEnter={() => setInfoGigi("36")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 36 "
                        onMouseEnter={() => setInfoGigi("36")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 36 "
                        onMouseEnter={() => setInfoGigi("36")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 36 "
                        onMouseEnter={() => setInfoGigi("36")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 36 "
                        onMouseEnter={() => setInfoGigi("36")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>36</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 37 "
                        onMouseEnter={() => setInfoGigi("37")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 37 "
                        onMouseEnter={() => setInfoGigi("37")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 37 "
                        onMouseEnter={() => setInfoGigi("37")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 37 "
                        onMouseEnter={() => setInfoGigi("37")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 37 "
                        onMouseEnter={() => setInfoGigi("37")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>37</div>
  
                </div>
  
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
          
                    <g>
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 38 "
                        onMouseEnter={() => setInfoGigi("38")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 40,0 30,10 10,10"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 38 "
                        onMouseEnter={() => setInfoGigi("38")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 38 "
                        onMouseEnter={() => setInfoGigi("38")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 38 "
                        onMouseEnter={() => setInfoGigi("38")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                      <polygon
                        onClick={(e) => handlePolygonClickSatuBagian(e, "tooth_number")}
                        data-nomor-gigi=" 38 "
                        onMouseEnter={() => setInfoGigi("38")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                    </g>
                  </svg>
                  <div style={{ textAlign: "center" }}>38</div>
  
                </div>
              </div>
      
            </div>
            </Col>
                :
             <></>   
            }
                
            {formchoiceGigi?.choiceGigi === "per_gigi" ?
              <Col className="mt-2" xl='12' sm='12' style={{padding:"0px 0px", fontSize:"12px"}} >
              {/* Gigi Atas Dewasa */}
              <div style={{ display: "flex" }}> 
                <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>18</div>
                    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 18B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("18-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 18P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 18M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 18D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 18O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                </div>
        
                <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>17</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 17B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("17-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 17P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("17-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 17M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("17-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 17D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("17-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 17O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("17-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>16</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 16B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("16-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 16P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 16M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 16D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 16O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>15</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 15B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("15-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 15P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 15M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 15D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 15O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>14</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 14B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("14-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 14P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("14-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 14M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("14-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 14D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("14-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 14O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("14-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>13</div>
                    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 13L "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("13-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 13P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("13-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 13M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("13-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 13D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("13-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>12</div>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 12L "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("12-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 12P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("12-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 12M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("12-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 12D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("12-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>11</div>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 11L "  
                        onMouseEnter={() => setInfoGigi("11-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 11P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("11-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 11M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("11-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 11D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("11-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                </div>
                </div>
        
                <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
            
                <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>21</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 21L "  
                  onMouseEnter={() => setInfoGigi("21-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 21P "  
                  onMouseEnter={() => setInfoGigi("21-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 21D "  
                  onMouseEnter={() => setInfoGigi("21-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 21M "  
                  onMouseEnter={() => setInfoGigi("21-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
                </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>22</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 22L "  
                  onMouseEnter={() => setInfoGigi("22-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 22P "  
                  onMouseEnter={() => setInfoGigi("22-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 22D "  
                  onMouseEnter={() => setInfoGigi("22-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 22M "  
                  onMouseEnter={() => setInfoGigi("22-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>23</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 23L "  
                  onMouseEnter={() => setInfoGigi("23-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 23P "  
                  onMouseEnter={() => setInfoGigi("23-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 23D "  
                  onMouseEnter={() => setInfoGigi("23-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 23M "  
                  onMouseEnter={() => setInfoGigi("23-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
          </div> 
                
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>24</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 24B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("24-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 24P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 24D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 24M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 24O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>25</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 25B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("25-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 25P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 25D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 25M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 25O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>26</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 26B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("26-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 26P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 26D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 26M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 26O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>27</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 27B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("27-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 27P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 27D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 27M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 27O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>28</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 28B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("28-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 28P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 28D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 28M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 28O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
            </div>
            
          
                </div> 
              </div>
        
              {/* Gigi Atas Anak */}
              <div style={{display:"flex"}}> 
                <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>55</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 55B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("55-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 55P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("55-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 55M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("55-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 55D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("55-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 55O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("55-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{textAlign:"center"}}>54</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 54B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("54-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 54P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("54-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 54M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("54-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 54D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("54-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 54O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("54-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>53</div>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 53L "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("53-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 53P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("53-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 53M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("53-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 53D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("53-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>52</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 52L "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("52-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 52P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("52-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 52M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("52-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 52D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("52-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <div style={{ textAlign: "center" }}>51</div>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 51L "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("51-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 51P "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("51-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 51M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("51-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 51D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("51-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                </div>
                </div>
        
                <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
            
                <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>61</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 61L "  
                  onMouseEnter={() => setInfoGigi("61-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 61P "  
                  onMouseEnter={() => setInfoGigi("61-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 61D "  
                  onMouseEnter={() => setInfoGigi("61-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 61M "  
                  onMouseEnter={() => setInfoGigi("61-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>62</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 62L "  
                  onMouseEnter={() => setInfoGigi("62-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 62P "  
                  onMouseEnter={() => setInfoGigi("62-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 62D "  
                  onMouseEnter={() => setInfoGigi("62-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 62M "  
                  onMouseEnter={() => setInfoGigi("62-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{ textAlign: "center" }}>63</div>
        
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
              <g>
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 63L "  
                  onMouseEnter={() => setInfoGigi("63-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,0 40,0 30,20 0,20"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 63P "  
                  onMouseEnter={() => setInfoGigi("63-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="0,20 30,20 100,100 0,100"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 63D "  
                  onMouseEnter={() => setInfoGigi("63-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")} 
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                <polygon
                  onClick={(e) => handlePolygonClick(e, "tooth_number")}
                  data-nomor-gigi=" 63M "  
                  onMouseEnter={() => setInfoGigi("63-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
            </svg>
          </div> 
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>64</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 64B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("64-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 64P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("64-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 64D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("64-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 64M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("64-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 64O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("64-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <div style={{textAlign:"center"}}>65</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 65B " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("65-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 65P "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("65-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 65D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("65-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 65M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("65-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 65O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("65-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            </div>
          
                </div>
              </div>
        
                {/* Gigi Bawah Anak */}
              <div className="mt-2" style={{display:"flex"}}> 
                <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 85L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("85-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 85B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("85-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 85M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("85-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 85D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("85-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 85O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("85-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>85</div>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 84L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("84-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 84B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("84-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 84M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("84-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 84D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("84-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 84O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("84-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>84</div>
        
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 83P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("83-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 83L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("83-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 83M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("83-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 83D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("83-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                    <div style={{ textAlign: "center" }}>83</div>
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 82P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("82-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 82L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("82-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 82M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("82-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 82D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("82-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                    </svg>
                    <div style={{ textAlign: "center" }}>82</div>
        
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 81P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("81-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 81L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("81-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 81M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("81-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 81D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("81-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{ textAlign: "center" }}>81</div>
                  </div>
                </div>
        
                <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
            
                <div style={{ width: "42px", margin: "5px" }}>
        
                <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 71P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("71-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 71L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("71-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 71D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("71-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 71M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("71-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            <div style={{ textAlign: "center" }}>71</div>
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 72P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("72-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 72L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("72-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 72D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("72-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 72M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("72-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>72</div>
        
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 73P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("73-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 73L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("73-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 73D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("73-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 73M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("73-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
            <div style={{ textAlign: "center" }}>73</div>
          </div> 
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 74L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("74-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 74B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("74-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 74D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("74-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 74M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("74-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 74O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("74-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>74</div>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 75L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("75-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 75B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("75-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 75D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("75-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 75M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("75-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 75O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("75-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>75</div>
        
            </div>  
          
                </div>
              </div>
        
                {/* Gigi Bawah Dewasa */}
              <div style={{display:"flex"}}> 
                <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
            
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 48L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("48-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 48B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("48-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 48M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("48-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 48D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("48-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 48O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("48-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>48</div>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 47L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("47-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 47B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("47-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 47M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("47-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 47D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("47-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 47O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("47-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>47</div>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 46L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("46-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 46B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("46-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 46M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("46-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 46D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("46-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 46O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("46-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>46</div>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 45L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("45-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 45B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("45-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 45M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("45-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 45D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("45-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 45O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("45-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>45</div>
        
                  </div>
        
                  <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 44L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("44-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 44B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("44-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 44M "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("44-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 44D "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("44-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 44O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("44-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                <div style={{textAlign:"center"}}>44</div>
        
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 43P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("43-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 43L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("43-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 43M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("43-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 43D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("43-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>43</div>
        
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 42P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("42-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 42L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("42-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 42M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("42-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 42D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("42-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>42</div>
        
                  </div>
                  
                  <div style={{ width: "42px", margin: "5px" }}>
        
                  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 41P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("41-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 41L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("41-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 41M " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("41-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 41D " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("41-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>41</div>
        
                </div>
                </div>
        
                <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 31P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("31-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 31L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("31-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 31D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("31-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 31M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("31-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>31</div>
        
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 32P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("32-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 32L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("32-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 32D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("32-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 32M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("32-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>32</div>
        
            </div>
            
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                    <g>
                      {/* Top  */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 33P "    
                        points="0,0 40,0 30,20 0,20"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("33-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                          
                      {/* Botton */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 33L "    
                        points="0,20 30,20 100,100 0,100"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("33-Labial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Right */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 33D " 
                        points="30,20 40,0 40,40 35,30"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("33-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Left     */}
                      <polygon
                        onClick={(e) => handlePolygonClick(e, "tooth_number")}
                        data-nomor-gigi=" 33M " 
                        points="0,0 5,10 10,20 0,40"
                        style={{ fill: 'white', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("33-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
                    <div style={{ textAlign: "center" }}>33</div>
        
          </div> 
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 34L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("34-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 34B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("34-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 34D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("34-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 34M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("34-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 34O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("34-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>34</div>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 35L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("35-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 35B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("35-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 35D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("35-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 35M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("35-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 35O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("35-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>35</div>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 36L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("36-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 36B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("36-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 36D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("36-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 36M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("36-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 36O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("36-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>36</div>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 37L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("37-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 37B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("37-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 37D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("37-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 37M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("37-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 37O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("37-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>37</div>
        
            </div>
        
            <div style={{ width: "42px", margin: "5px" }}>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black" }}>
                      <g>
                      {/* Atas */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 38L " 
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}  
                      onMouseEnter={() => setInfoGigi("38-Lingual")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                        
                      {/* Bawah */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 38B "          
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("38-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kanan */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 38D "          
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("38-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                      
                      {/* Kiri */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 38M "          
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("38-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
        
                      {/* Center */}
                      <polygon
                      onClick={(e) => handlePolygonClick(e, "tooth_number")}
                      data-nomor-gigi=" 38O "                
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("38-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </g>
                  </svg>
          <div style={{textAlign:"center"}}>38</div>
        
            </div>
            
                </div>
              </div>
              </Col> 
                :
              <></>   
            }
                
            <div className="mt-3" style={{ display: "flex" }}>
              <div>
                <div className="button-treatment-plan-add" style={{border:"none", backgroundColor:"", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px", cursor:"pointer"}} onClick={handleAddClick}>
                  <FaPlus style={{marginRight:"5px", fontSize:"10px"}}/>Add
                </div>
              </div>
              <div className="ml-2">
                <div className="button-treatment-plan-reset" style={{border:"none", backgroundColor:"", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px", cursor:"pointer"}} onClick={handleResetPolygon}>
                  <FaUndoAlt style={{marginRight:"5px", fontSize:"10px"}}/>Reset
                </div>
              </div>
            </div>
          </>
        </div>
   
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </div>    
    <div>
      {/* {getDataFee.map((user,index) => (
        <div  key={index} className="" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          {user?.name}
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={user?.price}/>        
          </div>
        </div>
      </div>
          ))}   */}

      {/* <div className="" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Harga Obat
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_obat}`}/>
          </div>
        </div>
      </div> */}
        
      {/* <div className="mt-1" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Harga Treatment
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_total}`}/>                
          </div>
        </div>
      </div> */}
            
      {/*         
      <div style={{display:"flex",textAlign:"",justifyContent:"end", borderBottom:"1px solid #C0C0C0", width:"28%"}}>+</div>
          <div className="mt-2" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center", fontWeight:"bold"}}>
          Total Harga 
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:"bold"}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_obat + form?.estimate_price_total + totalDataFeePrice}`}/>          </div>
        </div>
      </div> */}
    
        </div>
          <table className="table mt-4">
          <thead>
            <tr style={{backgroundColor:"#00F3EB"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>NO</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>KETERANGAN</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>ESTIMATE PRICE</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>ACTION</th>
            </tr>
          </thead>
        <tbody>
          {getDataFee.map((user,index) => (
            <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
              <td style={{ lineHeight: "1", border: "none" }}>{index + 1}</td>
              <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>{user?.name.toUpperCase()}</td>
              <td style={{ lineHeight: "1", border: "none" }}>1</td>
              <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                  Rp
                </div>
                <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                  {formatToRupiah(user?.price)}
                </div>
              </td>
            </tr>
          ))}
            
              
          {inputList.map((user,index) => (
            <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
              <td style={{ lineHeight: "2", border: "none" }}>{getDataFee.length + index + 1}</td>
              <td style={{ lineHeight: "2", border: "none", textAlign: "start" }}>
              {getDataTreatment.map(treatment => {
                if(treatment.id === user.id_treatment){
                  return treatment.name.toUpperCase()      
                }
              })}
                {user?.tooth_number === null || user?.tooth_number === "null" ? "" : 
                  getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ?
                  (user?.tooth_number === " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 " && "(RA+RB)") ||
                  (user?.tooth_number === " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 " && "(RA+RB)") ||
                  (user?.tooth_number === " 55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75 " && "(RA+RB)") ||
                  (user?.tooth_number === " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65 " && "(RA)") ||
                  (user?.tooth_number === " 18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28 " && "(RA)") ||
                  (user?.tooth_number === " 55  54  53  52  51  61  62  63  64  65 " && "(RA)") ||
                  (user?.tooth_number === " 85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 " && "(RB)") ||
                  (user?.tooth_number === " 48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38 " && "(RB)") ||
                  (user?.tooth_number === " 85  84  83  82  81  71  72  73  74  75 " && "(RB)") 
                  :
                  `(${user?.tooth_number})`                          
                }
              </td>   
              <td style={{ lineHeight: "2", border: "none" }}>{getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty}</td>
              <td style={{ lineHeight: "2", border: "none", display: "flex" }}>
                <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                  Rp
                </div>
                <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                  {formatToRupiah(user?.estimate_price*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty))}
                </div>
              </td>
                  
              {/* <td style={{ lineHeight: "3" }}>
                <input className="focused" style={{ width: "100%", border: "1px solid #DCDCDC", textAlign: "center", borderRadius:"5px" }}></input>
              </td> */}
        
              <td style={{ lineHeight: "1",border: "none"}}>
                <div className="" style={{display:"flex", justifyContent:"center", alignItems:"start"}}>
                  <div className="" style={{border:"none", backgroundColor:"red", color:"white", borderRadius:"3px", fontSize:"12px", padding:"4px 10px", cursor:"pointer"}} onClick={() => handleRemoveClick(index)}>remove</div>
                </div>
              </td>
            </tr>
          ))}
              
               {/* {inputList.map((user,index) => (
            <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
              <td style={{ lineHeight: "2", border: "none" }}>{getDataFee.length + index + 1}</td>
              <td style={{ lineHeight: "2", border: "none", textAlign: "start" }}>
              {getDataTreatment.map(treatment => {
                if (treatment.id === user.id_treatment) {
                  return treatment.name.toUpperCase() + " " + (user?.tooth_number.toUpperCase() ?? "");
                }
              })}
              </td>   
              <td style={{ lineHeight: "2", border: "none" }}>{user?.qty}</td>
              <td style={{ lineHeight: "2", border: "none", display: "flex" }}>
                <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                  Rp
                </div>
                <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                  {formatToRupiah(user?.estimate_price)}
                </div>
              </td>
                  
              
              <td style={{ lineHeight: "1",border: "none"}}>
                <div className="" style={{display:"flex", justifyContent:"center", alignItems:"start"}}>
                  <div className="" style={{border:"none", backgroundColor:"red", color:"white", borderRadius:"3px", fontSize:"12px", padding:"4px 10px", cursor:"pointer"}} onClick={() => handleRemoveClick(index)}>remove</div>
                </div>
              </td>
            </tr>
            ))} */}

            <tr className="mt-4"  style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center", borderTop:"1px solid #C0C0C0"}}>
              <td style={{ lineHeight: "1", border: "none" }}></td>
              <td style={{ lineHeight: "1", border: "none" }}></td>
              <td style={{ lineHeight: "1", border: "none" }}></td>
              <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
              <div style={{ flex: "10%", display: "flex", justifyContent: "start", fontWeight:"bold" }}>
                TOTAL
              </div>
              <div style={{ flex: "90%", display: "flex", justifyContent: "end", fontWeight:"bold" }}>
                Rp {formatToRupiah(toltalDataTreatmentPriceOn)}
              </div>
            </td>
            <td style={{ lineHeight: "2", border: "none" }}></td>

            </tr>
          </tbody>
        </table>
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"center" }}>
          <div style={{width:"20%"}}>
            <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
              Simpan Treatment 
            </Button>
          </div>
        </div>
      </Form>    
    :     
      <>
        <table className="table">
          <thead>
            <tr style={{backgroundColor:"#00F3EB"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>NO</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif", }}>KETERANGAN</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>JUMLAH</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>ACTION</th>
              {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>OBAT</th> */}
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>HARGA</th>
            </tr>
          </thead>
          <tbody>
            {getDataFee.map((user,index) => (
              <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                <td style={{ lineHeight: "1", border: "none" }}>{index + 1}</td>
                <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>{user?.name.toUpperCase()}</td>
                <td style={{ lineHeight: "1", border: "none" }}>{user?.qty}</td>
                <td style={{ lineHeight: "1", border: "none" }}>
                  {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Button block style={{padding:"0px 20px",fontSize:"12px",borderRadius:"5px", backgroundColor:"#FF8B06", border:"none"}}>
                        Sudah Approve 
                      </Button>
                    </div>
                  </div> */}
                </td>
                <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                  <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                    Rp
                  </div>
                  <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                    {formatToRupiah(user?.price)}
                  </div>
                </td>
              </tr>
            ))}
          
            {getDataDetailsApproval.map((user, index) => (
            <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center", }}>
              <td style={{ lineHeight: "1", border: "none" }}>{getDataFee.length + index + 1}</td>
                <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                  <div>
                  {user?.treatment_data.name.toUpperCase()}  
                  {user?.tooth_number === null || user?.tooth_number === "null" ? "" : 
                  getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ?
                  (user?.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                  (user?.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                  (user?.tooth_number === "55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75" && "(RA+RB)") ||
                  (user?.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                  (user?.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28" && "(RA)") ||
                  (user?.tooth_number === "55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                  (user?.tooth_number === "85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                  (user?.tooth_number === "48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                  (user?.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "(RB)") 
                  :
                  `(${user?.tooth_number})`                          
                  }
                  </div>
                  <div style={{fontWeight:"800"}}>
                    DIAGNOSA : {user?.description === null? "" : user?.description.toUpperCase()}
                  </div>
                </td>
                <td style={{ lineHeight: "1", border: "none" }}>{getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty}</td>
                <td style={{ lineHeight: "1", border: "none" }}>
                  {user?.patient_approval === 1 ?  
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Button block style={{padding:"3px 10px",fontSize:"12px",borderRadius:"3px", backgroundColor:"#00D580", border:"none", cursor:""}}>
                        <FaCheckCircle style={{marginRight:"5px"}}/>
                        Sudah Approve
                      </Button>
                    </div>
                  </div>
                    : 
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Button onClick={() => {ApproveTreatment(user?.id, user?.description, user?.id_description , user?.code_description)}}  block style={{padding:"3px 20px",fontSize:"12px",borderRadius:"3px", backgroundColor:"#0000D5", border:"none"}}>
                        Approve 
                      </Button>
                    </div>
                  </div>
                  }
                </td>

                {/* <td style={{ lineHeight: "1", border: "none" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <Button onClick={() => {ApproveTreatment(user?.id, user?.description)}}  block style={{padding:"3px 20px",fontSize:"12px",borderRadius:"3px", backgroundColor:"#0000D5", border:"none"}}>
                          Obat 
                        </Button>
                      </div>
                    </div>
                </td> */}

                <td style={{ lineHeight: "1", border:"none", display: "flex"}}>
                  <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                    Rp
                  </div>
                  <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                    {formatToRupiah(user?.estimate_price*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.id_treatment).length > 0 ? 1 : user?.qty))}
                  </div>
                </td>
            </tr>
            ))}
              
          </tbody>
        </table>
      
        <div style={{display:"flex", justifyContent:"end"}}>
          <div style={{flex:"50%"}}>  
          </div>
          <div style={{ flex: "50%", backgroundColor:"#C6C6C6", display: "flex", fontSize: "12px", fontWeight: "bold", padding: "5px 5px", borderRight: "none", borderLeft: "none" }}>
            <div style={{ flex: "50%" }}>
              TOTAL
            </div>
            <div style={{ flex: "50%", display: "flex", alignItems: "center" }}>
              <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                Rp
              </div>
              <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                <span id="totalPrice">
                  <FormatRupiah type="text" value={`${totalDataTreatmentPrice + totalDataFeePrice}`} />
                </span> 
              </div>
            </div>
          </div>
        </div>
          
        <div style={{ padding: "30px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
          <div style={{ width: "20%" }}>
            <Button onClick={handleSubmitMedicalInvoice} className="mt-4" type='' block style={{backgroundColor:"#3F3FFF", padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
              Konfirmasi Treatment
            </Button>
          </div>
        </div>
      </> 
    }

    </div>
  );
}
