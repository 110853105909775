import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../Loader/LoaderHome"
import ModalDataPatient from "./ModalPatientAdd/ModalAdd"
import HeaderPatient from "./Components/HeaderPatient";
import TablePatient from "./Components/TablePatient";
import PaginationPatient from "./Components/PaginationPatient";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const [modalUpdateDataPatient, setModalUpdateDataPatient] = useState(false);
  const [idUpdatePatient , setIdUpdatePatient] = useState(false);
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patients?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${keyword}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`/transaction-service/patients/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  useEffect(() => {
    GetResponseData()
  }, [page])

  const buttonRefresh = () => {
    window.location.reload();
  };

  const odontogramPatient = (id, first_name, last_name) => {
    navigate('/odontograms/' + id + "/" + first_name + "/" + last_name);
  };

  const soapPatient = (id, first_name, last_name) => {
    navigate('/soap-patients/' + id + "/" + first_name + "/" + last_name);
  };

  let modalPhysicalCheck = async (id) => {
    navigate('/phy-checks/'+ id)
  };

  let modalMedicalRecord = async (id, code) => {
    navigate('/medical-record/'+ id + "/" + code)
  };

  const modalMedisRoleAdd = (id_patient) => {
    setModalUpdateDataPatient(true)
    setIdUpdatePatient(id_patient)
  }

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalUpdateDataPatient && <ModalDataPatient GetResponseData={GetResponseData} idUpdatePatient={idUpdatePatient} show={modalUpdateDataPatient} onHide={() => setModalUpdateDataPatient(false)} />}
      {loading && <LoaderHome />}
  
      {/* start header */}
      <HeaderPatient
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        buttonRefresh={buttonRefresh}
        modalMedisRoleAdd={modalMedisRoleAdd}
      />
      {/* end header */}
      <Col xl='12' sm='12'> 
        <div>
          {/* start table */}
          <TablePatient
            getData={getData}
            page={page}
            modalPhysicalCheck={modalPhysicalCheck}
            modalMedicalRecord={modalMedicalRecord}
            soapPatient={soapPatient}
            odontogramPatient={odontogramPatient}
            deleteById={deleteById}
            isTabletOrMobile={isTabletOrMobile}
          />
          {/* end table */}
        </div>
        {/* start pagination */}
          <PaginationPatient
            rows={rows}
            page={page}
            pages={pages}
            msg={msg}
            changePage={changePage}
          />
        {/* end pagination */}
      </Col>
    </div>
  );
}
