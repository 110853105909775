import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-slice.png"
import SignatureCanvas from 'react-signature-canvas'
import { FaBackspace, FaBackward, FaCamera, FaHandHoldingMedical, FaReply, FaReplyAll, FaReplyd, FaSearch, FaUndo, FaUndoAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import React, { useRef } from 'react';
import Dropdown from "../Auth/Dropdown";
import LoaderHome from "../../components/Loader/LoaderHome"
import Swal from "sweetalert2";
import "../../index.css"
import { ScaleLoader } from "react-spinners"
import LogoDatePicker from "../../assets/signature/logo-date-pick.png";

export default function Login() {
  let navigate = useNavigate();
  document.title = "Register Pasien";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const nama = localStorage.getItem("username")
  const [isFocused, setIsFocused] = useState(false);
  const [getCode, setGetCode] = useState([]);

  const previewReset = () => {
    setPreview(null)
    setImageContent([])
  }
 
  const [form, setForm] = useState({
    firts_name: "",
    last_name: "",
    gender: "",
    day_birth: "",
    month_birth: "",
    year_birth: "",
    date_birth: "",
    address: "",
	  phone_number: "",
    email: "",
    password: "",
    confirmPassword: "",
    code : `${getCode}`
  });

  const {
    firts_name,
    last_name,
    gender,
    day_birth,
    month_birth,
    year_birth,
    date_birth,
    address,
	  phone_number,
    email,
    password,
    confirmPassword
  } = form;

  const generateCode = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.post(`user-service/master-codes/generate`,
        {
          type: "emr",
        })
      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  // useEffect(() => {
  //   generateCode()
  // },[])

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    });

    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
     // Configuration
     const config = {
      headers: {
        "Content-type": "application/json",
      },
     };
      
    if (form?.confirmPassword === "") {
    setLoading(false)
    return Swal.fire(
      'Confirm Password Harus Di Isi',
      'Mohon Periksa',
      'warning'
    )
  }
  
  if (form?.password != form?.confirmPassword) {
    setLoading(false)
    return Swal.fire(
      'Password Anda Dengan Confirm Password Berbeda',
      'Mohon Periksa',
      'warning'
    )
  }
    const response = await API.post(`transaction-service/patient-registrations/store`, 
    {
      first_name: firts_name? firts_name : "",
      last_name: last_name? last_name : "",
      gender: gender? gender : "",
      date_birth: date_birth ? date_birth : "",
      phone_number: phone_number ? phone_number : "",
      address: address? address : "",
      email: email? email : "",
      password: password ,
      residence_address: address? address : "",
      // medical_record_code : form?.code
    }
    , config)

    // if (form?.email === "") {
    //   setLoading(false)
    //   return Swal.fire(
    //     '',
    //     'Mohon Periksa',
    //     'warning'
    //   )
    // }
    
    if (response?.status === 200) {
    setLoading(false)
    // Swal.fire(
    //   'Selamat Anda Berhasil Registrasi',
    //   'Silahkan Cek Email Anda Untuk Aktivasi Akun',
    //   'success'
    // )
    Swal.fire(
      'Selamat Anda Berhasil Registrasi',
      'Silahkan Login Untuk Membuat Appointment',
      'success'
    )
    navigate("/")
    }
      
    } catch (error) {
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const modalViewTreatment = ()=>{
    setModalShow(true)
  }

  const navigateHome = () => {
    if (window.confirm('Apakah kamu yakin mau keluar dari halaman ini?')) {
      // Navigasi ke halaman utama atau tujuan lain
      navigate("/"); // Ini akan mengarahkan pengguna ke halaman sebelumnya
    }
    
  }
 
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const firstNameInputRef = useRef(null);

  // Ketika komponen dimuat, fokus akan diarahkan ke input "Nama Depan"
  React.useEffect(() => {
    firstNameInputRef.current.focus();
  }, []);


  return (
    <div style={{ fontFamily: "sans-serif" }}>
      {loading && <LoaderHome />}
      {/* <Col xl="12" style={{ padding: "0px 0px", position: "fixed"}}>
      <div style={{display: "flex", justifyContent: "end", marginTop: "100px" }}>
        <Dropdown />  
      </div>
      </Col> */}
    {isTabletOrMobile ?
    <div>
    <div xl='12' sm='12' style={{ backgroundColor:"white"}}>
    <form onSubmit={(e) =>postUser} style={{width:"100%"}}>
      <div style={{ display: "flex", backgroundColor: "white", borderBottom:"1px solid #E6E6E6", padding:"10px 0px"}}>
        <div style={{ flex: "60%", fontSize: "15px", display: "flex", alignItems: "center", justifyContent:"center"}}>
          <div>
            <div style={{fontWeight:"bold", color:"#373737"}}>
              Pendaftaran Pasien Baru
            </div>
            <div style={{fontSize:"12px", color:"#979797"}}>
              PT. Signature Anugerah Sentosa
            </div>
          </div>
        </div> 
            
        <div style={{ flex: "40%"}}>
          <div style={{display:"flex", justifyContent:"center"}}>
            <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "100px"}}></img>
          </div>
        
      </div> 
      </div>
  
    <div style={{ backgroundColor: "white", borderBottom:"5px solid #E1E1E1"}}>
    <div style={{ padding: "10px 15px" }}>
                  
      <div className="mt-2" style={{ display: "flex"}}>
        <div style={{ width: "100%" }}>
          <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Depan</div>
              <input  className="focused" type="text" placeholder='Masukan nama depan anda'  name="firts_name" ref={firstNameInputRef} value={firts_name} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", borderRadius:"5px", paddingLeft:"5px", height:"7vh"}}/>
            {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div> */}
          </div>             
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
        <div style={{ width: "100%" }}>
          <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Belakang</div>
              <input  className="focused" type="text" placeholder='Masukan nama belakang anda'  name="last_name" value={last_name} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", borderRadius:"5px", paddingLeft:"5px", height:"7vh"}}/>
            {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div> */}
          </div>             
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Jenis Kelamin</div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange}  name="gender" style={{ textAlign:"", cursor:"pointer", height:"7vh"}}>
              <option value="" hidden>Pilih</option>
              <option value="Laki-Laki" style={{textAlign:""}}>Laki-Laki</option>
              <option value="Wanita" style={{textAlign:""}}>Wanita</option>              
            </select>                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
          </div>            
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Lahir</div>
                  <input  className="focused" type="Date" placeholder='Masukan Nama Belakang Anda'  name="date_birth" onChange={handleChange}  style={{
                  width: "100%",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "7vh",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px',
                  }}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>            
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nomor Handphone</div>
                  <input  className="focused" type="number" placeholder='Masukan nomor handphone anda' value={phone_number} name="phone_number" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"",  height:"7vh", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>          
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Alamat</div>
                  <input  className="focused" type="text" placeholder='Masukan alamat anda' value={address} name="address" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>             
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
        <div style={{ width: "100%" }}>
          <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Email</div>
              <input  className="focused" type="email" placeholder='@gmail.com'  name="email" value={email} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
            <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div>
          </div>             
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Password</div>
                  <input  className="focused" type="password" placeholder='***********'  name="password" value={password} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>          
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex"}}>
                  <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Confirm Passsword</div>
                  <input  className="focused" type="password" placeholder='***********'  name="confirmPassword" value={confirmPassword} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"7vh", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>            
      </div>
       
                  
        {/* <div className="" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Depan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={firts_name} name="firts_name" ref={firstNameInputRef} onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
            </div>
          </div>
        </div>
                
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama Belakang
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={last_name} name="last_name" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
            </div>
          </div>
        </div>
                  
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Jenis Kelamin
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="gender" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="Laki-Laki" style={{textAlign:""}}>Laki-Laki</option>
              <option value="Wanita" style={{textAlign:""}}>Wanita</option>              
            </select>
          </div>
        </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Tanggal Lahir
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "5px", height: "100%", display: "flex", alignItems: "center" }}>:</p>
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="day_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Day</option>
              <option value="01" style={{textAlign:"center"}}>1</option>
              <option value="02" style={{textAlign:"center"}}>2</option>
              <option value="03" style={{textAlign:"center"}}>3</option>
              <option value="04" style={{textAlign:"center"}}>4</option>
              <option value="05" style={{textAlign:"center"}}>5</option>
              <option value="06" style={{textAlign:"center"}}>6</option>
              <option value="07" style={{textAlign:"center"}}>7</option>
              <option value="08" style={{textAlign:"center"}}>8</option>
              <option value="09" style={{textAlign:"center"}}>9</option>
              <option value="10" style={{textAlign:"center"}}>10</option>
              <option value="11" style={{textAlign:"center"}}>11</option>
              <option value="12" style={{textAlign:"center"}}>12</option>
              <option value="13" style={{textAlign:"center"}}>13</option>
              <option value="14" style={{textAlign:"center"}}>14</option>
              <option value="15" style={{textAlign:"center"}}>15</option>
              <option value="16" style={{textAlign:"center"}}>16</option>
              <option value="17" style={{textAlign:"center"}}>17</option>
              <option value="18" style={{textAlign:"center"}}>18</option>
              <option value="19" style={{textAlign:"center"}}>19</option>
              <option value="20" style={{textAlign:"center"}}>20</option>
              <option value="21" style={{textAlign:"center"}}>21</option>
              <option value="22" style={{textAlign:"center"}}>22</option>
              <option value="23" style={{textAlign:"center"}}>23</option>
              <option value="24" style={{textAlign:"center"}}>24</option>
              <option value="25" style={{textAlign:"center"}}>25</option>
              <option value="26" style={{textAlign:"center"}}>26</option>
              <option value="27" style={{textAlign:"center"}}>27</option>
              <option value="28" style={{textAlign:"center"}}>28</option>
              <option value="29" style={{textAlign:"center"}}>29</option>
              <option value="30" style={{textAlign:"center"}}>30</option>
              <option value="31" style={{textAlign:"center"}}>31</option>    
            </select>
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="month_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Month</option>
              <option value="01" style={{ textAlign:"center"}}>Januari</option>
              <option value="02" style={{textAlign:"center"}}>Februari</option>
              <option value="03" style={{textAlign:"center"}}>Maret</option>
              <option value="04" style={{textAlign:"center"}}>April</option>
              <option value="05" style={{textAlign:"center"}}>Mei</option>
              <option value="06" style={{textAlign:"center"}}>Juni</option>
              <option value="07" style={{textAlign:"center"}}>Juli</option>
              <option value="08" style={{textAlign:"center"}}>Agustus</option>
              <option value="09" style={{textAlign:"center"}}>September</option>
              <option value="10" style={{textAlign:"center"}}>Oktober</option>
              <option value="11" style={{textAlign:"center"}}>November</option>
              <option value="12" style={{textAlign:"center"}}>Desember</option>    
            </select> 
                      
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="year_birth" style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
            <option value="" hidden>Year</option>
            <option value="1940">1940</option>
            <option value="1941">1941</option>
            <option value="1942">1942</option>
            <option value="1943">1943</option>
            <option value="1944">1944</option>
            <option value="1945">1945</option>
            <option value="1946">1946</option>
            <option value="1947">1947</option>
            <option value="1948">1948</option>
            <option value="1949">1949</option>
            <option value="1950">1950</option>
            <option value="1951">1951</option>
            <option value="1952">1952</option>
            <option value="1953">1953</option>
            <option value="1954">1954</option>
            <option value="1955">1955</option>
            <option value="1956">1956</option>
            <option value="1957">1957</option>
            <option value="1958">1958</option>
            <option value="1959">1959</option>
            <option value="1960">1960</option>
            <option value="1961">1961</option>
            <option value="1962">1962</option>
            <option value="1963">1963</option>
            <option value="1964">1964</option>
            <option value="1965">1965</option>
            <option value="1966">1966</option>
            <option value="1967">1967</option>
            <option value="1968">1968</option>
            <option value="1969">1969</option>
            <option value="1970">1970</option>
            <option value="1971">1971</option>
            <option value="1972">1972</option>
            <option value="1973">1973</option>
            <option value="1974">1974</option>
            <option value="1975">1975</option>
            <option value="1976">1976</option>
            <option value="1977">1977</option>
            <option value="1978">1978</option>
            <option value="1979">1979</option>
            <option value="1980">1980</option>
            <option value="1981">1981</option>
            <option value="1982">1982</option>
            <option value="1983">1983</option>
            <option value="1984">1984</option>
            <option value="1985">1985</option>
            <option value="1986">1986</option>
            <option value="1987">1987</option>
            <option value="1988">1988</option>
            <option value="1989">1989</option>
            <option value="1990">1990</option>
            <option value="1991">1991</option>
            <option value="1992">1992</option>
            <option value="1993">1993</option>
            <option value="1994">1994</option>
            <option value="1995">1995</option>
            <option value="1996">1996</option>
            <option value="1997">1997</option>
            <option value="1998">1998</option>
            <option value="1999">1999</option>
            <option value="2000">2000</option>
            <option value="2001">2001</option>
            <option value="2002">2002</option>
            <option value="2003">2003</option>
            <option value="2004">2004</option>
            <option value="2005">2005</option>
            <option value="2006">2006</option>
            <option value="2007">2007</option>
            <option value="2008">2008</option>
            <option value="2009">2009</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>         
            </select>
          </div>
        </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Alamat
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={address} name="address" onChange={handleChange}  style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
            </div>
          </div>
        </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            No. Telp / HP
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
            <input autoFofcus type='text' value={phone_number} name="phone_number" onChange={handleChange}  style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
          </div>
          </div>
                  </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Email
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
            <input autoFofcus type='email' value={email} name="email" onChange={handleChange}  style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
          </div>
          </div>
        </div>
        
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Password
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
            <input autoFofcus type='password' value={password} name="password" onChange={handleChange}  style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
          </div>
          </div>
        </div>
        
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
           Confirm Password
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"", padding:"10px 0px"}}>
            <input autoFofcus type='password' value={confirmPassword} name="confirmPassword" onChange={handleChange}  style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #804D00", outline:""}}/>
          </div>
          </div>
        </div> */}
            
      <div className="mt-4" style={{ display: "flex", justifyContent: "end", padding: "20px 0px 20px 0px" }}>
        <div className="mr-4" style={{width:"200px"}}>
          <Button  onClick={navigateHome}  color='secondary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Kembali
          </Button>
        </div>
        <div style={{width:"200px"}}>
          <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Daftar
          </Button>
        </div>
      </div>
                  
      </div>
    </div>
  
        </form>
      </div>
    </div>
        :
      <Row>
        <Col xl='12' sm='12' style={{ backgroundColor:"#ECF1FF", padding: "50px 350px 50px 350px", fontFamily:"lato" }}>
          <form onSubmit={(e) =>postUser} style={{width:"100%"}}>
          <div style={{ display: "flex", backgroundColor: "white", borderBottom:"1px solid #E6E6E6", padding:"20px 0px"}}>
            <div style={{ flex: "60%", fontSize: "25px", display: "flex", alignItems: "center", justifyContent:"center"}}>
              <div>
                <div style={{fontWeight:"bold", color:"#373737"}}>
                  Pendaftaran Pasien Baru
                </div>
                <div style={{fontSize:"12px", color:"#979797"}}>
                  PT. Signature Anugerah Sentosa
                </div>
              </div>
            </div> 
                
            <div style={{ flex: "40%"}}>
              <div style={{display:"flex", justifyContent:"center"}}>
                <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "100px"}}></img>
              </div>
            
            </div> 
              </div>
              
        
          <div style={{ backgroundColor: "white", borderBottom:"5px solid #E1E1E1"}}>
            <div style={{ padding: "10px 30px" }}>
              
            <div className="mt-3" style={{ display: "flex" }}>
              
                  </div> 
                  
        <div className="" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Depan</div>
                  <input className="focused" type="text" placeholder='Masukan nama depan anda' value={firts_name} name="firts_name" ref={firstNameInputRef} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nama Belakang</div>
                  <input className="focused" type="text" placeholder='Masukan nama belakang anda' value={last_name} name="last_name" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
         
        </div>
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Jenis Kelamin</div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange}  name="gender" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Pilih</option>
              <option value="Laki-Laki" style={{textAlign:""}}>Laki-Laki</option>
              <option value="Wanita" style={{textAlign:""}}>Wanita</option>              
            </select>                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
          </div>
          </div>
          <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Lahir</div>
                <input className="focused" type="Date" placeholder='Masukan Nama Belakang Anda' name="date_birth" onChange={handleChange}
                  style={{
                  width: "100%",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px',
                  }}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
        </div>
                  

        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nomor Handphone</div>
                  <input  className="focused" type="number" placeholder='Masukan nomor handphone anda' value={phone_number} name="phone_number" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Alamat</div>
                  <input  className="focused" type="text" placeholder='Masukan alamat anda' value={address} name="address" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>
          </div>

          <div className="mt-4" style={{ display: "flex"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Email</div>
                  <input  className="focused" type="email" placeholder='@gmail.com'  name="email" value={email} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Email Wajib Aktif</div>
              </div>             
          </div>
         
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Password</div>
                  <input  className="focused" type="password" placeholder='***********'  name="password" value={password} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
              </div>
              <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Konfirmasi Passsword</div>
                  <input  className="focused" type="password" placeholder='***********'  name="confirmPassword" value={confirmPassword} onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
              </div>
          </div>   
      </div>
            
      <div className="mt-4" style={{ display: "flex", justifyContent: "center", padding: "20px 0px 20px 0px" }}>
        <div className="mr-4" style={{width:"200px"}}>
          <Button onClick={navigateHome}  color='secondary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Kembali
          </Button>
        </div>
        <div style={{width:"200px"}}>
          <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px"}}>
            Daftar
          </Button>
        </div>
      </div>
                        
        </div>
      </div>
        
      </form>
            
        </Col>
      </Row>
      }
    </div>
  );
}
